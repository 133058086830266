// Enable & Disable pages / components
export const dashboardViewEnabled: boolean = true;
export const stakeViewEnabled: boolean = true;
export const bondsViewEnabled: boolean = true;
export const wrapViewEnabled: boolean = true;

export const enableDarkLightTheme: boolean = true;
export const showAddTokensPopper: boolean = true;

export const connectedChainId = 250 // Fantom Mainnet
// export const connectedChainIdRPC = "https://solitary-lingering-wave.fantom.quiknode.pro/75d81dec7f1097db66960ba223ca122928de1a67/" // Fantom Mainnet
export const connectedChainIdRPC = "https://rpcapi.fantom.network" // Fantom Mainnet
