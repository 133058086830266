import { useSelector } from "react-redux";
import {
  Box,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Zoom,
} from "@material-ui/core";
import { BondDataCard, BondTableData } from "./BondRow";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { formatCurrency } from "../../helpers";
import useBonds from "../../hooks/Bonds";
import { useWeb3Context } from "src/hooks/web3Context";

import "./choosebond.scss";
import { Skeleton } from "@material-ui/lab";
import ClaimBonds from "./ClaimBonds";
import isEmpty from "lodash/isEmpty";
import { allBondsMap } from "src/helpers/AllBonds";

import ChartIcon from "../../assets/icons/chart.svg";
import TreasureIcon from "../../assets/icons/treasure.svg";
import TagIcon from "../../assets/icons/tag.svg";

function ChooseBond() {
  const { chainID } = useWeb3Context();
  const { bonds } = useBonds(chainID);
  const isSmallScreen = useMediaQuery("(max-width: 733px)"); // change to breakpoint query
  const isVerySmallScreen = useMediaQuery("(max-width: 420px)");

  const isAppLoading = useSelector(state => state.app.loading);
  // const isAccountLoading = useSelector(state => state.account.loading);

  const accountBonds = useSelector(state => {
    const withInterestDue = [];
    for (const bond in state.account.bonds) {
      if (state.account.bonds[bond].interestDue > 0) {
        withInterestDue.push(state.account.bonds[bond]);
      }
    }
    return withInterestDue;
  });

  const marketPrice = useSelector(state => {
    return state.app.marketPrice;
  });

  const treasuryBalance = useSelector(state => {
    if (state.bonding.loading == false) {
      let tokenBalances = 0;
      for (const bond in allBondsMap) {
        if (state.bonding[bond] && !state.bonding[bond].dontAddTreasuryBalance) {
          tokenBalances += state.bonding[bond].purchased;
        }
      }
      return tokenBalances;
    }
  });

  return (
    <div id="choose-bond-view">
      <div className="page-wrapper-bg"></div>
      <Zoom in={true}>
        <div class="main-container">
          <div className="main-title">
            <h1>Bond (1,1)</h1>
          </div>

          <Grid container item xs={12} spacing={2} className="bond-hero">
            <Grid item xs={6}>
              <Box textAlign={`${isVerySmallScreen ? "left" : "center"}`}>
                <div className="bond-block">
                  <div className="bond-icon"><span><img src={TreasureIcon} /></span></div>
                  <div className="bond-content">
                    <Typography variant="h5" color="textSecondary">
                      Treasury Balance
                    </Typography>
                    <Typography variant="h4">
                      {isAppLoading ? (
                        <Skeleton width="180px" />
                      ) : isNaN(treasuryBalance)
                        ? (<>0</>)
                        : (
                          new Intl.NumberFormat("en-US", {
                            style: "currency",
                            currency: "USD",
                            maximumFractionDigits: 2,
                            minimumFractionDigits: 2,
                          }).format(treasuryBalance)
                        )}
                    </Typography>
                  </div>
                </div>
              </Box>
            </Grid>

            <Grid item xs={6} className={`orkan-price`}>
              <Box textAlign={`${isVerySmallScreen ? "right" : "center"}`}>
                <div className="bond-block">
                  <div className="bond-icon"><span><img src={TagIcon} /></span></div>
                  <div className="bond-content">
                    <Typography variant="h5" color="textSecondary">
                      ORKAN Price
                    </Typography>
                    <Typography variant="h4">
                      {isAppLoading ? <Skeleton width="100px" /> : isNaN(marketPrice) ? 0 : formatCurrency(marketPrice, 2)}
                    </Typography>
                  </div>
                </div>
              </Box>
            </Grid>
          </Grid>
        </div>
      </Zoom>
      {!isEmpty(accountBonds) && <ClaimBonds activeBonds={accountBonds} />}
      <Zoom in={true}>
        <Paper className="orkan-card">
          <div className="main-title">
            <h2>Overview</h2>
          </div>

          {!isSmallScreen && (
            <Grid container item>
              <TableContainer>
                <Table aria-label="Available bonds">
                  <TableHead>
                    <TableRow>
                      <TableCell align="left">Bond</TableCell>
                      <TableCell align="left">Price</TableCell>
                      <TableCell align="left">Discount</TableCell>
                      <TableCell align="right">Purchased</TableCell>
                      <TableCell align="right">Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {bonds.map(bond => (
                      <BondTableData key={bond.name} bond={bond} />
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          )}
        </Paper>
      </Zoom>

      {isSmallScreen && (
        <Box className="orkan-card-container">
          <Grid container item spacing={2}>
            {bonds.map(bond => (
              <Grid item xs={12} key={bond.name}>
                <BondDataCard key={bond.name} bond={bond} />
              </Grid>
            ))}
          </Grid>
        </Box>
      )}
    </div>
  );
}

export default ChooseBond;
