import { ethers } from "ethers";
import { addresses } from "../constants";
import { abi as StrudelStakingv2ABI } from "../abi/StrudelStakingv2.json";
import { abi as sORKANv2 } from "../abi/sOrkanv2.json";
import { setAll, getMarketPrice, getChainBaseToken } from "../helpers";
import apollo from "../lib/apolloClient.js";
import { createSlice, createSelector, createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "src/store";
import { IBaseAsyncThunk } from "./interfaces";
import { StrudelStakingv2, SOrkanv2 } from "../typechain";
import { BigNumber } from 'bignumber.js';

const initialState = {
  loading: false,
  loadingMarketPrice: false,
  loadingBaseTokenPrice: false,
};

export const loadAppDetails = createAsyncThunk("app/loadAppDetails", async ({ networkID, provider }: IBaseAsyncThunk, { dispatch }) => {
  const protocolMetricsQuery = `
      query {
        _meta {
          block {
            number
          }
        }
        protocolMetrics(first: 1, orderBy: timestamp, orderDirection: desc) {
          timestamp
          orkanCirculatingSupply
          sOrkanCirculatingSupply
          totalSupply
          orkanPrice
          marketCap
          totalValueLocked
          treasuryMarketValue
          nextEpochRebase
          nextDistributedOrkan
        }
      }
    `;

  const graphData = await apollo(protocolMetricsQuery);

  if (!graphData || graphData == null) {
    console.error("Returned a null response when querying TheGraph");
    return;
  }

  const stakingTVL = parseFloat(graphData.data.protocolMetrics[0].totalValueLocked);
  // NOTE (appleseed): marketPrice from Graph was delayed, so get CoinGecko price
  // const marketPrice = parseFloat(graphData.data.protocolMetrics[0].orkanPrice);
  let marketPrice;
  try {
    const originalPromiseResult = await dispatch(loadMarketPrice({ networkID: networkID, provider: provider })).unwrap();
    marketPrice = originalPromiseResult?.marketPrice;
  } catch (rejectedValueOrSerializedError) {
    // handle error here
    console.error("Returned a null response from dispatch(loadMarketPrice)");
    return;
  }

  // NOTE (appleseed): marketPrice from Graph was delayed, so get CoinGecko price
  // const marketPrice = parseFloat(graphData.data.protocolMetrics[0].orkanPrice);
  let baseTokenPrice;
  try {
    const originalPromiseResult = await dispatch(loadBaseTokenPrice({ networkID: networkID, provider: provider })).unwrap();
    baseTokenPrice = originalPromiseResult?.baseTokenPrice;
  } catch (rejectedValueOrSerializedError) {
    // handle error here
    console.error("Returned a null response from dispatch(loadBaseTokenPrice)");
    return;
  }

  const marketCap = parseFloat(graphData.data.protocolMetrics[0].marketCap);
  const circSupply = parseFloat(graphData.data.protocolMetrics[0].orkanCirculatingSupply);
  const totalSupply = parseFloat(graphData.data.protocolMetrics[0].totalSupply);
  const treasuryMarketValue = parseFloat(graphData.data.protocolMetrics[0].treasuryMarketValue);
  // const currentBlock = parseFloat(graphData.data._meta.block.number);

  if (!provider) {
    console.error("failed to connect to provider, please connect your wallet");
    return {
      stakingTVL,
      marketPrice,
      marketCap,
      circSupply,
      totalSupply,
      treasuryMarketValue,
    };
  }
  const currentBlock = await provider.getBlockNumber();
  const stakingContract = new ethers.Contract(
    addresses[networkID].STAKING_ADDRESS as string,
    StrudelStakingv2ABI,
    provider,
  ) as StrudelStakingv2;

  const sorkanMainContract = new ethers.Contract(
    addresses[networkID].SORKAN_ADDRESS as string,
    sORKANv2,
    provider,
  ) as SOrkanv2;

  // Calculating staking
  const epoch = await stakingContract.epoch();
  const stakingReward = epoch.distribute;
  const circ = await sorkanMainContract.circulatingSupply();
  const stakingRebaseCalc = Number(stakingReward.toString()) / Number(circ.toString());
  const stakingRebase = isNaN(stakingRebaseCalc) ? 0 : stakingRebaseCalc;
  const fiveDayRateCalc = Math.pow(1 + stakingRebase, 5 * 3) - 1;
  const fiveDayRate = isNaN(fiveDayRateCalc) ? 0 : fiveDayRateCalc;
  const stakingAPYCalc = Math.pow(1 + stakingRebase, 365 * 3) - 1;
  const stakingAPY = isNaN(stakingAPYCalc) ? 0 : stakingAPYCalc;

  // Current index
  const currentIndex = await stakingContract.index();
  return {
    currentIndex: ethers.utils.formatUnits(currentIndex, "gwei"),
    currentBlock,
    fiveDayRate,
    stakingAPY,
    stakingTVL,
    stakingRebase,
    marketCap,
    marketPrice,
    circSupply,
    totalSupply,
    treasuryMarketValue,
  } as IAppData;
},
);

/**
 * checks if app.slice has marketPrice already
 * if yes then simply load that state
 * if no then fetches via `loadMarketPrice`
 *
 * `usage`:
 * ```
 * const originalPromiseResult = await dispatch(
 *    findOrLoadMarketPrice({ networkID: networkID, provider: provider }),
 *  ).unwrap();
 * originalPromiseResult?.whateverValue;
 * ```
 */
export const findOrLoadMarketPrice = createAsyncThunk(
  "app/findOrLoadMarketPrice",
  async ({ networkID, provider }: IBaseAsyncThunk, { dispatch, getState }) => {
    const state: any = getState();
    let marketPrice;
    // check if we already have loaded market price
    if (state.app.loadBaseTokenPrice === false && state.app.marketPrice) {
      // go get marketPrice from app.state
      marketPrice = state.app.marketPrice;
    } else {
      // we don't have marketPrice in app.state, so go get it
      try {
        const originalPromiseResult = await dispatch(
          loadMarketPrice({ networkID: networkID, provider: provider }),
        ).unwrap();
        marketPrice = originalPromiseResult?.marketPrice;
      } catch (rejectedValueOrSerializedError) {
        // handle error here
        console.error("Returned a null response from dispatch(loadMarketPrice)");
        return;
      }
    }
    return { marketPrice };
  },
);

export const findOrLoadBaseTokenPrice = createAsyncThunk(
  "app/findOrLoadBaseTokenPrice",
  async ({ networkID, provider }: IBaseAsyncThunk, { dispatch, getState }) => {
    const state: any = getState();
    let baseTokenPrice;
    // check if we already have loaded market price
    if (state.app.loadBaseTokenPrice === false && state.app.baseTokenPrice) {
      // go get marketPrice from app.state
      baseTokenPrice = state.app.baseTokenPrice;
    } else {
      // we don't have marketPrice in app.state, so go get it
      try {
        const originalPromiseResult = await dispatch(loadBaseTokenPrice({ networkID: networkID, provider: provider })).unwrap();
        baseTokenPrice = originalPromiseResult?.baseTokenPrice;
      } catch (rejectedValueOrSerializedError) {
        // handle error here
        console.error("Returned a null response from dispatch(loadMarketPrice)");
        return;
      }
    }
    return { baseTokenPrice };
  },
);

/**
 * - fetches the ORKAN price from CoinGecko (via getTokenPrice)
 * - falls back to fetch marketPrice from orkan-dai contract
 * - updates the App.slice when it runs
 */
const loadMarketPrice = createAsyncThunk("app/loadMarketPrice", async ({ networkID, provider }: IBaseAsyncThunk) => {
  let marketPrice: number;
  marketPrice = await getMarketPrice({ networkID, provider });
  marketPrice = marketPrice / Math.pow(10, 9);
  return { marketPrice };
});

/**
 * - Calculates the FTM price
 */
const loadBaseTokenPrice = createAsyncThunk("app/loadBaseTokenPrice", async ({ networkID, provider }: IBaseAsyncThunk) => {
  let baseTokenPrice: number;
  baseTokenPrice = await getChainBaseToken(networkID, provider);
  return { baseTokenPrice };
});

interface IAppData {
  readonly circSupply: number;
  readonly currentIndex?: string;
  readonly currentBlock?: number;
  readonly fiveDayRate?: number;
  readonly marketCap: number;
  readonly marketPrice: number;
  readonly baseTokenPrice: number;
  readonly stakingAPY?: number;
  readonly stakingRebase?: number;
  readonly stakingTVL: number;
  readonly totalSupply: number;
  readonly treasuryBalance?: number;
  readonly treasuryMarketValue?: number;
}

const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    fetchAppSuccess(state, action) {
      setAll(state, action.payload);
    },
  },
  extraReducers: builder => {
    builder
      .addCase(loadAppDetails.pending, state => {
        state.loading = true;
      })
      .addCase(loadAppDetails.fulfilled, (state, action) => {
        setAll(state, action.payload);
        state.loading = false;
      })
      .addCase(loadAppDetails.rejected, (state, { error }) => {
        state.loading = false;
        console.error(error.name, error.message, error.stack);
      })
      .addCase(loadMarketPrice.pending, (state, action) => {
        state.loadingMarketPrice = true;
      })
      .addCase(loadMarketPrice.fulfilled, (state, action) => {
        setAll(state, action.payload);
        state.loadingMarketPrice = false;
      })
      .addCase(loadMarketPrice.rejected, (state, { error }) => {
        state.loadingMarketPrice = false;
        console.error(error.name, error.message, error.stack);
      })
      .addCase(loadBaseTokenPrice.pending, (state, action) => {
        state.loadingBaseTokenPrice = true;
      })
      .addCase(loadBaseTokenPrice.fulfilled, (state, action) => {
        setAll(state, action.payload);
        state.loadingBaseTokenPrice = false;
      })
      .addCase(loadBaseTokenPrice.rejected, (state, { error }) => {
        state.loadingBaseTokenPrice = false;
        console.error(error.name, error.message, error.stack);
      });
  },
});

const baseInfo = (state: RootState) => state.app;

export default appSlice.reducer;

export const { fetchAppSuccess } = appSlice.actions;

export const getAppState = createSelector(baseInfo, app => app);
