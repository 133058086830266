import { useQuery } from "react-query";
import { rebasesDataQuery } from "../treasuryData.js";
import { useWeb3Context } from "src/hooks/web3Context";

import apollo from "src/lib/apolloClient";

export const useTreasuryRebases = options => {
  const { chainID } = useWeb3Context();

  return useQuery("treasury_rebases", async () => {
    const response = await apollo(rebasesDataQuery(chainID));
    return response.data.rebases;
  }, options);
};
