import React, { useState } from "react";
import { addresses, TOKEN_DECIMALS } from "../../constants";
import { SvgIcon, Popper, Button, Paper, Typography, Box, Fade } from "@material-ui/core";
import { ReactComponent as InfoIcon } from "../../assets/icons/info-fill.svg";

import "./orkanmenu.scss";
import { useWeb3Context } from "../../hooks/web3Context";
import { showAddTokensPopper } from "src/helpers/envHelper";

import OrkanImg from "src/assets/tokens/token_ORKAN.svg";
import token33tImg from "src/assets/tokens/token_33T.svg";

const addTokenToWallet = (tokenSymbol, tokenAddress) => async () => {
  if (window.ethereum) {
    const host = window.location.origin;
    let tokenPath;
    let tokenDecimals = TOKEN_DECIMALS;
    switch (tokenSymbol) {
      case "ORKAN":
        tokenPath = OrkanImg;
        break;
      case "33T":
        tokenPath = token33tImg;
        break;
      case "wsORKAN":
        tokenPath = WsOrkanImg;
        tokenDecimals = 18;
        break;
      default:
        tokenPath = OrkanImg;
    }
    const imageURL = `${host}/${tokenPath}`;

    try {
      await window.ethereum.request({
        method: "wallet_watchAsset",
        params: {
          type: "ERC20",
          options: {
            address: tokenAddress,
            symbol: tokenSymbol,
            decimals: tokenDecimals,
            image: imageURL,
          },
        },
      });
    } catch (error) {
      console.log(error);
    }
  }
};

function OrkanMenu() {
  const [anchorEl, setAnchorEl] = useState(null);
  const isEthereumAPIAvailable = window.ethereum;
  const { chainID } = useWeb3Context();

  const networkID = chainID;
  const SORKAN_ADDRESS = addresses[networkID].SORKAN_ADDRESS;
  const ORKAN_ADDRESS = addresses[networkID].ORKAN_ADDRESS;
  const PT_TOKEN_ADDRESS = addresses[networkID].PT_TOKEN_ADDRESS;
  const WSORKAN_ADDRESS = addresses[networkID].WSORKAN_ADDRESS;
  const handleClick = event => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const open = Boolean(anchorEl);
  const id = "orkan-popper";
  return (
    <React.Fragment>
      {showAddTokensPopper ?
        <Box
          component="div"
          onMouseEnter={e => handleClick(e)}
          onMouseLeave={e => handleClick(e)}
          id="orkan-menu-button-hover"
        >
          <Button id="orkan-menu-button" size="large" variant="contained" color="secondary" title="ORKAN" aria-describedby={id}>
            <SvgIcon component={InfoIcon} color="primary" />
            <Typography>ORKAN</Typography>
          </Button>

          <Popper id={id} open={open} anchorEl={anchorEl} placement="bottom-start" transition>
            {({ TransitionProps }) => {
              return (
                <Fade {...TransitionProps} timeout={100}>
                  <Paper className="orkan-menu" elevation={1}>
                    {isEthereumAPIAvailable ? (
                      <Box className="add-tokens">
                        <p>ADD TOKEN TO WALLET</p>
                        <Box display="flex" flexDirection="row" justifyContent="space-between">
                          {ORKAN_ADDRESS && (
                            <Button variant="contained" color="secondary" onClick={addTokenToWallet("ORKAN", ORKAN_ADDRESS)}>
                              <img
                                src={OrkanImg}
                                style={{ height: "25px", width: "25px" }}
                              />
                              <Typography variant="body1">ORKAN</Typography>
                            </Button>
                          )}
                          {SORKAN_ADDRESS && (
                            <Button variant="contained" color="secondary" onClick={addTokenToWallet("sORKAN", SORKAN_ADDRESS)}>
                              <img
                                src={OrkanImg}
                                style={{ height: "25px", width: "25px" }}
                              />
                              <Typography variant="body1">sORKAN</Typography>
                            </Button>
                          )}
                          {WSORKAN_ADDRESS && (
                            <Button variant="contained" color="secondary" onClick={addTokenToWallet("wsORKAN", WSORKAN_ADDRESS)}>
                                <img
                                src={OrkanImg}
                                style={{ height: "25px", width: "25px" }}
                              />
                              <Typography variant="body1">wsORKAN</Typography>
                            </Button>
                          )}
                        </Box>
                      </Box>
                    ) : null}
                  </Paper>
                </Fade>
              );
            }}
          </Popper>
        </Box>
        :
        <></>
      }
    </React.Fragment>
  );
}

export default OrkanMenu;
