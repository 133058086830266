import './topbar.scss';

import { AppBar, Box, Button, Link, SvgIcon, Toolbar } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useCallback, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { addresses } from 'src/constants';
import { bondsViewEnabled, dashboardViewEnabled, enableDarkLightTheme, stakeViewEnabled, wrapViewEnabled } from 'src/helpers/envHelper';
import { useWeb3Context } from 'src/hooks';

import { ReactComponent as MenuIcon } from '../../assets/icons/hamburger.svg';
import OrkanLogoDark from '../../assets/orkan-logo-dark.svg';
import OrkanLogoLight from '../../assets/orkan-logo-light.svg';
import ConnectMenu from './ConnectMenu.jsx';
import OrkanMenu from './OrkanMenu.jsx';
import ThemeSwitcher from './ThemeSwitch.jsx';

const useStyles = makeStyles(theme => ({
	appBar: {
		[theme.breakpoints.up("sm")]: {
			width: "100%",
			padding: "10px",
		},
		justifyContent: "flex-end",
		alignItems: "flex-end",
		background: "transparent",
		backdropFilter: "none",
		zIndex: 10,
	},
	menuButton: {
		marginRight: theme.spacing(2),
		[theme.breakpoints.up("981")]: {
			display: "none",
		},
	},
}));

function TopBar({ theme, toggleTheme, handleDrawerToggle }) {
	const [isActive] = useState();
	const classes = useStyles();
	const isVerySmallScreen = useMediaQuery("(max-width: 355px)");
	const { connect, hasCachedProvider, provider, chainID, connected } = useWeb3Context();

	const checkPage = useCallback((match, location, page) => {
		const currentPath = location.pathname.replace("/", "");
		if (currentPath.indexOf("dashboard") >= 0 && page === "dashboard") {
			return true;
		}
		if (currentPath.indexOf("stake") >= 0 && page === "stake") {
			return true;
		}
		if (currentPath.indexOf("presale") >= 0 && page === "presale") {
			return true;
		}
		if ((currentPath.indexOf("bonds") >= 0 || currentPath.indexOf("choose_bond") >= 0) && page === "bonds") {
			return true;
		}
		return false;
	}, []);


	return (
		<AppBar position="static" className="header-main" elevation={0}>


			<div className="nav-main-wrapper">
				<div className="nav-logo">
					<img src={theme === "light" ? OrkanLogoDark : OrkanLogoLight} />
				</div>
				<div className="nav-main">
					{dashboardViewEnabled &&
						<Link
							component={NavLink}
							id="dash-nav"
							to="/dashboard"
							isActive={(match, location) => {
								return checkPage(match, location, "dashboard");
							}}
							className={`button-dapp-menu ${isActive ? "active" : ""}`}>
							<div>
								Dashboard
							</div>
						</Link>
					}

					{stakeViewEnabled &&
						<Link
							component={NavLink}
							id="stake-nav"
							to="/stake"
							isActive={(match, location) => {
								return checkPage(match, location, "stake");
							}}
							className={`button-dapp-menu ${isActive ? "active" : ""}`}>
							<div>
								Stake
							</div>
						</Link>
					}

					{wrapViewEnabled &&
						<Link
							component={NavLink}
							id="stake-nav"
							to="/wrap"
							isActive={(match, location) => {
								return checkPage(match, location, "wrap");
							}}
							className={`button-dapp-menu ${isActive ? "active" : ""}`}>
							<div>
								Wrap
							</div>
						</Link>
					}

					{bondsViewEnabled &&
						<Link
							component={NavLink}
							id="bond-nav"
							to="/bonds"
							isActive={(match, location) => {
								return checkPage(match, location, "bonds");
							}}
							className={`button-dapp-menu ${isActive ? "active" : ""}`}>
							<div>
								Bond
							</div>
						</Link>
					}

					<a
						href="https://app.beefy.finance/#/fantom/vault/spirit-orkan-ftm"
						target='_blank'
						className={`MuiTypography-root MuiLink-root MuiLink-underlineNone button-dapp-menu  MuiTypography-colorPrimary button-dapp-menu ${isActive ? "active" : ""}`}>
						<div>
							Vaults
						</div>
					</a>

					<a
						href="https://play.moonpot.com/#/pot/orkan-ftm"
						target='_blank'
						className={`MuiTypography-root MuiLink-root MuiLink-underlineNone button-dapp-menu  MuiTypography-colorPrimary button-dapp-menu ${isActive ? "active" : ""}`}>
						<div>
							Moonpot
						</div>
					</a>

				</div>
			</div>



			<Toolbar disableGutters className="dapp-topbar">
				<Button
					id="hamburger"
					aria-label="open drawer"
					edge="start"
					size="large"
					variant="contained"
					color="secondary"
					onClick={handleDrawerToggle}
					className={classes.menuButton}
				>
					<SvgIcon component={MenuIcon} />
				</Button>

				<Box display="flex">
					{enableDarkLightTheme && <ThemeSwitcher theme={theme} toggleTheme={toggleTheme} />}
					{!isVerySmallScreen && <OrkanMenu />}
					{window.innerWidth >= 768 &&
						<Button
							edge="start"
							size="large"
							variant="contained"
							color="secondary"
							onClick={() => window.open(`https://swap.spiritswap.finance/#/exchange/swap/FTM/${addresses[chainID].ORKAN_ADDRESS}`, '_blank')}>
							Buy Orkan
						</Button>
					}
					<ConnectMenu theme={theme} />
				</Box>
			</Toolbar>
		</AppBar>
	);
}

export default TopBar;
