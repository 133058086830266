import { ethers, BigNumber, BigNumberish } from "ethers";
import { contractForRedeemHelper } from "../helpers";
import { getBalances, calculateUserBondDetails } from "./AccountSlice";
import { findOrLoadMarketPrice } from "./AppSlice";
import { error, info } from "./MessagesSlice";
import { BigNumber as BN } from 'bignumber.js';
import { clearPendingTxn, fetchPendingTxns } from "./PendingTxnsSlice";
import { createAsyncThunk, createSelector, createSlice } from "@reduxjs/toolkit";
import { getNoneStandardBondCalculator, getStandardBondCalculator } from "src/helpers/BondCalculator";
import { RootState } from "src/store";
import { abi as TreasuryABI } from "src/abi/Treasury.json";
import { getChainBaseToken } from '../helpers/index';
import {
  IApproveBondAsyncThunk,
  IBondAssetAsyncThunk,
  ICalcBondDetailsAsyncThunk,
  IJsonRPCError,
  IRedeemAllBondsAsyncThunk,
  IRedeemBondAsyncThunk,
} from "./interfaces";
import { addresses } from "src/constants";
import { EthContract, Treasury } from "src/typechain";

export const changeApproval = createAsyncThunk(
  "bonding/changeApproval",
  async ({ address, bond, provider, networkID }: IApproveBondAsyncThunk, { dispatch }) => {
    if (!provider) {
      dispatch(error("Please connect your wallet!"));
      return;
    }

    const signer = provider.getSigner();
    const reserveContract = bond.getContractForReserve(networkID, signer);
    const bondAddr = bond.getAddressForBond(networkID);

    let approveTx;
    let bondAllowance = await reserveContract.allowance(address, bondAddr);

    // return early if approval already exists
    if (bondAllowance.gt(BigNumber.from("0"))) {
      dispatch(info("Approval completed."));
      dispatch(calculateUserBondDetails({ address, bond, networkID, provider }));
      return;
    }

    try {
      approveTx = await reserveContract.approve(bondAddr, ethers.utils.parseUnits("1000000000", "ether").toString());
      dispatch(
        fetchPendingTxns({
          txnHash: approveTx.hash,
          text: "Approving " + bond.displayName,
          type: "approve_" + bond.name,
        }),
      );
      await approveTx.wait();
    } catch (e: unknown) {
      dispatch(error((e as IJsonRPCError).message));
    } finally {
      if (approveTx) {
        dispatch(clearPendingTxn(approveTx.hash));
        dispatch(calculateUserBondDetails({ address, bond, networkID, provider }));
      }
    }
  },
);

export interface IBondDetails {
  bond: string;
  bondDiscount: number;
  debtRatio: number;
  bondQuote: number;
  purchased: number;
  vestingTerm: number;
  maxBondPrice: number;
  bondPrice: number;
  marketPrice: number;
  dontAddTreasuryBalance: boolean
}
export const calcBondDetails = createAsyncThunk(
  "bonding/calcBondDetails",
  async ({ bond, value, provider, networkID }: ICalcBondDetailsAsyncThunk, { dispatch }): Promise<IBondDetails> => {
    if (!value) {
      value = "0";
    }
    const amountInWei = ethers.utils.parseEther(value);

    // const vestingTerm = VESTING_TERM; // hardcoded for now
    let bondPrice: ethers.BigNumber | BN = BigNumber.from(0), bondDiscount = 0, valuation = 0, bondQuote: BigNumberish = BigNumber.from(0);
    const bondContract = bond.getContractForBond(networkID, provider);
    const bondCalcContract = getStandardBondCalculator(networkID, provider);
    const treasuryContract = new ethers.Contract(addresses[networkID].TREASURY_ADDRESS, TreasuryABI, provider) as Treasury;
    const principalAddress = bond.getAddressForReserve(networkID);

    const terms = await bondContract.terms();
    const maxBondPrice = await bondContract.maxPayout();
    let debtRatio: BigNumberish = "0";
    // !!!: NOTE: If the `usesStandardBondingCalculator` = false that means that the BOND is using the NON Standard bonding calculator
    // !!!: NOTE: If the `usesStandardBondingCalculator` = true that means that the BOND is using the Standard bonding calculator
    try {
      if (bond.usesStandardBondingCalculator === true) {
        debtRatio = await bondContract.standardizedDebtRatio();
        debtRatio = Number(debtRatio.toString()) / Math.pow(10, 9);
      }
    } catch {
      // !!!: TODO FIX THE DEBT RATIO
      console.log(`%c DEBT RATIO IS NOT WORKING FOR THE BOND: ${bond.name}`, 'color: #FF0000');
    }

    let marketPrice: number = 0;
    try {
      const originalPromiseResult = await dispatch(findOrLoadMarketPrice({ networkID: networkID, provider: provider })).unwrap();
      marketPrice = originalPromiseResult?.marketPrice;
    } catch (rejectedValueOrSerializedError) {
      // handle error here
      console.log("Returned a null response from dispatch(loadMarketPrice)");
    }

    console.log('bond', bond)
    // !!!: NOTE: If the `usesStandardBondingCalculator` = true it uses the `bondPriceInUSD` function 
    // !!!: NOTE: If the `usesStandardBondingCalculator` = false it does not use the `bondPriceInUSD` function but we create our own bond price calculation
    if (bond.usesStandardBondingCalculator === true) {
      if(bond && bond.newFloor) {
        const rate = await bond.getBondRate(networkID, provider);
        const newBondPrice = await bondContract.bondPriceInUSD();
        
        bondPrice = new BN(newBondPrice.toString()).times(rate);
        bondDiscount = (marketPrice * Math.pow(10, 18) - Number(bondPrice.toString())) / Number(bondPrice.toString()); // marketPrice - bondPrice / (bondPrice * Math.pow(10, 9));
      } else {
        bondPrice = await bondContract.bondPriceInUSD();
        bondDiscount = (marketPrice * Math.pow(10, 18) - Number(bondPrice.toString())) / Number(bondPrice.toString()); // marketPrice - bondPrice / (bondPrice * Math.pow(10, 9));
      }
    } else {
      const bp = await bondContract.bondPrice();
      const markdown = await bondCalcContract.markdown(principalAddress);
      const ftmPrice = await getChainBaseToken(networkID, provider);
      let newMarkdown = 0;
      if(bond.divideByOrkan) {
        newMarkdown = (Number(markdown) * (Number(marketPrice)));
      } else {
        newMarkdown = (Number(markdown)*(Number(ftmPrice)));
      }
      const priceCalc = (Number(bp) * (Number(newMarkdown)) / 100);
      bondPrice = new BN(priceCalc).div(1e21).times(1e19)
      if(bond.divideByOrkan) {
        bondDiscount = (marketPrice * Math.pow(10, 18) - Number(bondPrice.toString())) / Number(bondPrice.toString()) / 10; // market price - bondPrice / (bondPrice * Math.pow(10, 9));
      } else {
        bondDiscount = (marketPrice * Math.pow(10, 18) - Number(bondPrice.toString())) / Number(bondPrice.toString()); // market price - bondPrice / (bondPrice * Math.pow(10, 9));
      }
    }

    if (Number(value) === 0) {
      // if inputValue is 0 avoid the bondQuote calls
      bondQuote = BigNumber.from(0);
    } else if (bond.isLP) {
      if (bond.usesStandardBondingCalculator === true) {
        valuation = Number((await bondCalcContract.valuation(principalAddress, amountInWei)).toString());
        bondQuote = await bondContract.payoutFor(valuation);
      } else {
        const bondCalcContract = getNoneStandardBondCalculator(networkID, provider);
        valuation = Number((await bondCalcContract.valuation(principalAddress, amountInWei)).toString());
        bondQuote = await bondContract.payoutFor(valuation);
      }

      if (!amountInWei.isZero() && Number(bondQuote.toString()) < 100000) {
        bondQuote = BigNumber.from(0);
        const errorString = "Amount is too small!";
        dispatch(error(errorString));
      } else {
        bondQuote = Number(bondQuote.toString()) / Math.pow(10, 9);
      }

    } else {
      const valueOfAmount = await treasuryContract._valueOf(principalAddress, amountInWei);
      bondQuote = await bondContract.payoutFor(valueOfAmount);
      

      if (!amountInWei.isZero() && Number(bondQuote.toString()) < 100000000) {
        bondQuote = BigNumber.from(0);
        const errorString = "Amount is too small!";
        dispatch(error(errorString));
      } else {
        bondQuote = Number(bondQuote.toString()) / Math.pow(10, 9);
      }
    }

    // Display error if user tries to exceed maximum.
    if (!!value && parseFloat(bondQuote.toString()) > Number(maxBondPrice.toString()) / Math.pow(10, 9)) {
      const errorString = "You're trying to bond more than the maximum payout available! The maximum bond payout is " + (Number(maxBondPrice.toString()) / Math.pow(10, 9)).toFixed(2).toString() + " ORKAN.";
      dispatch(error(errorString));
    }

    // Calculate bonds purchased
    const purchased = await bond.getTreasuryBalance(networkID, provider);
    const price = Number(bondPrice.toString()) / Math.pow(10, 18);

    return {
      bond: bond.name,
      bondDiscount,
      debtRatio: Number(debtRatio.toString()),
      bondQuote: Number(bondQuote.toString()),
      purchased,
      vestingTerm: Number(terms.vestingTerm.toString()),
      maxBondPrice: Number(maxBondPrice.toString()) / Math.pow(10, 9),
      bondPrice: price,
      marketPrice: marketPrice,
      dontAddTreasuryBalance: bond.dontAddTreasuryBalance
    };
  },
);

export const bondAsset = createAsyncThunk(
  "bonding/bondAsset",
  async ({ value, address, bond, networkID, provider, slippage }: IBondAssetAsyncThunk, { dispatch }) => {
    const depositorAddress = address;
    const acceptedSlippage = slippage / 100 || 0.005; // 0.5% as default
    // parseUnits takes String => BigNumber
    const valueInWei = ethers.utils.parseUnits(value.toString(), "ether");
    console.log('valueInWei', valueInWei.toString())
    // Calculate maxPremium based on premium and slippage.
    // const calculatePremium = await bonding.calculatePremium();
    const signer = provider.getSigner();
    const bondContract = bond.getContractForBond(networkID, signer);
    const calculatePremium = await bondContract.bondPrice();
    const maxPremium = Math.round(Number(calculatePremium.toString()) * (1 + acceptedSlippage));

    // Deposit the bond
    let bondTx;
    let uaData = {
      address: address,
      value: value,
      type: "Bond",
      bondName: bond.displayName,
      approved: true,
      txHash: "",
    };
    try {
      bondTx = await bondContract.deposit(valueInWei, maxPremium, depositorAddress);
      dispatch(fetchPendingTxns({ txnHash: bondTx.hash, text: "Bonding " + bond.displayName, type: "bond_" + bond.name }));
      uaData.txHash = bondTx.hash;
      await bondTx.wait();
    } catch (e: any) {
      console.log('e', e);
      const rpcError = e as IJsonRPCError;
      if (rpcError.code === -32603 && rpcError.message.indexOf("ds-math-sub-underflow") >= 0) {
        console.log('e if', e);
        dispatch(error("You may be trying to bond more than your balance! Error code: 32603. Message: ds-math-sub-underflow"));
      } else {
        const errorMessage = e && e.data && e.data.message ? e.data.message : e.message;
        dispatch(error(errorMessage))
      };
    } finally {
      if (bondTx) {
        dispatch(clearPendingTxn(bondTx.hash));
        dispatch(calculateUserBondDetails({ address, bond, networkID, provider }));
      }
    }
  },
);

export const redeemBond = createAsyncThunk(
  "bonding/redeemBond",
  async ({ address, bond, networkID, provider, autostake }: IRedeemBondAsyncThunk, { dispatch }) => {
    if (!provider) {
      dispatch(error("Please connect your wallet!"));
      return;
    }

    const signer = provider.getSigner();
    const bondContract = bond.getContractForBond(networkID, signer);

    let redeemTx;
    let uaData = {
      address: address,
      type: "Redeem",
      bondName: bond.displayName,
      autoStake: autostake,
      approved: true,
      txHash: "",
    };
    try {
      redeemTx = await bondContract.redeem(address, autostake === true);
      const pendingTxnType = "redeem_bond_" + bond + (autostake === true ? "_autostake" : "");
      uaData.txHash = redeemTx.hash;
      dispatch(
        fetchPendingTxns({ txnHash: redeemTx.hash, text: "Redeeming " + bond.displayName, type: pendingTxnType }),
      );

      await redeemTx.wait();
      await dispatch(calculateUserBondDetails({ address, bond, networkID, provider }));

      dispatch(getBalances({ address, networkID, provider }));
    } catch (e: unknown) {
      uaData.approved = false;
      dispatch(error((e as IJsonRPCError).message));
    } finally {
      if (redeemTx) {
        dispatch(clearPendingTxn(redeemTx.hash));
      }
    }
  },
);

export const redeemAllBonds = createAsyncThunk(
  "bonding/redeemAllBonds",
  async ({ bonds, address, networkID, provider, autostake }: IRedeemAllBondsAsyncThunk, { dispatch }) => {
    if (!provider) {
      dispatch(error("Please connect your wallet!"));
      return;
    }

    const signer = provider.getSigner();
    const redeemHelperContract = contractForRedeemHelper({ networkID, provider: signer });

    let redeemAllTx;

    try {
      redeemAllTx = await redeemHelperContract.redeemAll(address, autostake);
      const pendingTxnType = "redeem_all_bonds" + (autostake === true ? "_autostake" : "");

      await dispatch(
        fetchPendingTxns({ txnHash: redeemAllTx.hash, text: "Redeeming All Bonds", type: pendingTxnType }),
      );

      await redeemAllTx.wait();

      bonds &&
        bonds.forEach(async bond => {
          dispatch(calculateUserBondDetails({ address, bond, networkID, provider }));
        });

      dispatch(getBalances({ address, networkID, provider }));
    } catch (e: unknown) {
      dispatch(error((e as IJsonRPCError).message));
    } finally {
      if (redeemAllTx) {
        dispatch(clearPendingTxn(redeemAllTx.hash));
      }
    }
  },
);

// Note(zx): this is a barebones interface for the state. Update to be more accurate
interface IBondSlice {
  status: string;
  [key: string]: any;
}

const setBondState = (state: IBondSlice, payload: any) => {
  const bond = payload.bond;
  const newState = { ...state[bond], ...payload };
  state[bond] = newState;
  state.loading = false;
};

const initialState: IBondSlice = {
  status: "idle",
};

const bondingSlice = createSlice({
  name: "bonding",
  initialState,
  reducers: {
    fetchBondSuccess(state, action) {
      state[action.payload.bond] = action.payload;
    },
  },

  extraReducers: builder => {
    builder
      .addCase(calcBondDetails.pending, state => {
        state.loading = true;
      })
      .addCase(calcBondDetails.fulfilled, (state, action) => {
        setBondState(state, action.payload);
        state.loading = false;
      })
      .addCase(calcBondDetails.rejected, (state, { error }) => {
        state.loading = false;
        console.error(error.message);
      });
  },
});

export default bondingSlice.reducer;

export const { fetchBondSuccess } = bondingSlice.actions;

const baseInfo = (state: RootState) => state.bonding;

export const getBondingState = createSelector(baseInfo, bonding => bonding);

