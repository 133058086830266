import { EPOCH_INTERVAL, BLOCK_RATE_SECONDS, addresses } from "../constants";
import { ethers } from "ethers";
import { BigNumber } from "bignumber.js";
import { abi as PairContractABI } from "../abi/PairContract.json";
import { abi as RedeemHelperABI } from "../abi/RedeemHelper.json";
import { SvgIcon } from "@material-ui/core";
import { ReactComponent as OrkanImg } from "../assets/tokens/token_ORKAN.svg";
import { ReactComponent as SOrkanImg } from "../assets/tokens/token_sORKAN.svg";
import { orkan_ftm, usdc_ftm } from "./AllBonds";
import { JsonRpcSigner, StaticJsonRpcProvider } from "@ethersproject/providers";
import { IBaseAsyncThunk } from "src/slices/interfaces";
import { PairContract, RedeemHelper } from "../typechain";
import { connectedChainIdRPC } from "./envHelper";

import axios from "axios";

export async function getChainBaseToken(networkID: any, provider: any) {
  const usdcWethAddress = usdc_ftm.getAddressForReserve(networkID);
  const usdcWethpairContract = new ethers.Contract(usdcWethAddress, PairContractABI, provider) as PairContract;
  const usdcWethReserves = await usdcWethpairContract.getReserves();
  const usdcWethReserve0 = new BigNumber(usdcWethReserves[0].toString())
  const usdcWethReserve1 = new BigNumber(usdcWethReserves[1].toString())
  const baseTokenPrice = Number(usdcWethReserve0.div(usdcWethReserve1).times(1e12));
  return baseTokenPrice;
}

export async function getBondRate(pairAddress: string, oldPrice: number, networkID: any, rotateReserves: boolean, isWBTC?: boolean) {
  const provider: StaticJsonRpcProvider = new StaticJsonRpcProvider(connectedChainIdRPC);
  const pairContract = new ethers.Contract(pairAddress, PairContractABI, provider) as PairContract;
  const baseTokenPrice = await getChainBaseToken(networkID, provider);
  const getReserves = await pairContract.getReserves();
  const reserve0 = new BigNumber(getReserves[0].toString())
  const reserve1 = new BigNumber(getReserves[1].toString())
  let tokenPrice = 0;
  let newPrice = 0;
  if(isWBTC) {
    tokenPrice = await getTokenPrice('wrapped-bitcoin')
  } else if(!rotateReserves) {
    tokenPrice = Number(reserve0.div(reserve1));
  } else {
    tokenPrice = Number(reserve1.div(reserve0));
  }

    newPrice = (tokenPrice * baseTokenPrice);
  
  return newPrice / oldPrice;
}

export async function getMarketPrice({ networkID, provider }: IBaseAsyncThunk) {
  let marketPrice: number = 0;
  const baseTokenPrice = await getChainBaseToken(networkID, provider);
  const orkanFTMAddress = orkan_ftm.getAddressForReserve(networkID);
  const orkanFTMPairContract = new ethers.Contract(orkanFTMAddress, PairContractABI, provider) as PairContract;
  const orkanFTMReserves = await orkanFTMPairContract.getReserves();
  const orkanPrice = Number(orkanFTMReserves[0].toString()) / Number(orkanFTMReserves[1].toString());
  marketPrice = (orkanPrice * baseTokenPrice);

  return marketPrice;
}

export async function getTokenPrice(tokenId: string) {
  const resp = await axios.get(`https://api.coingecko.com/api/v3/simple/price?ids=${tokenId}&vs_currencies=usd`);
  let tokenPrice: number = resp.data[tokenId].usd;
  return tokenPrice;
}

export function shorten(str: string) {
  if (str.length < 10) return str;
  return `${str.slice(0, 6)}...${str.slice(str.length - 4)}`;
}

export function formatCurrency(c: number, precision = 0) {
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    maximumFractionDigits: precision,
    minimumFractionDigits: precision,
  }).format(c);
}

export function trim(number = 0, precision = 0) {
  // why would number ever be undefined??? what are we trimming?
  const array = number.toString().split(".");
  if (array.length === 1) return number.toString();
  if (precision === 0) return array[0].toString();

  const poppedNumber = array.pop() || "0";
  array.push(poppedNumber.substring(0, precision));
  const trimmedNumber = array.join(".");
  return trimmedNumber;
}

export function getRebaseBlock(currentBlock: number) {
  return currentBlock + EPOCH_INTERVAL - (currentBlock % EPOCH_INTERVAL);
}

export function secondsUntilBlock(startBlock: number, endBlock: number) {
  const blocksAway = endBlock - startBlock;
  const secondsAway = blocksAway * BLOCK_RATE_SECONDS;

  return secondsAway;
}

export function prettyVestingPeriod(currentBlock: number, vestingBlock: number) {
  if (vestingBlock === 0) {
    return "";
  }

  const seconds = secondsUntilBlock(currentBlock, vestingBlock);
  if (seconds < 0) {
    return "Fully Vested";
  }
  return prettifySeconds(seconds);
}

export function prettifySeconds(seconds: number, resolution?: string) {
  if (seconds !== 0 && !seconds) {
    return "";
  }

  const d = Math.floor(seconds / (3600 * 24));
  const h = Math.floor((seconds % (3600 * 24)) / 3600);
  const m = Math.floor((seconds % 3600) / 60);

  if (resolution === "day") {
    return d + (d == 1 ? " day" : " days");
  }

  const dDisplay = d > 0 ? d + (d == 1 ? " day, " : " days, ") : "";
  const hDisplay = h > 0 ? h + (h == 1 ? " hr, " : " hrs, ") : "";
  const mDisplay = m > 0 ? m + (m == 1 ? " min" : " mins") : "";

  let result = dDisplay + hDisplay + mDisplay;
  if (mDisplay === "") {
    result = result.slice(0, result.length - 2);
  }

  return result;
}

function getSorkanTokenImage() {
  return <SvgIcon component={SOrkanImg} viewBox="0 0 100 100" style={{ height: "1rem", width: "1rem" }} />;
}

export function getOrkanTokenImage(w?: number, h?: number) {
  const height = h == null ? "32px" : `${h}px`;
  const width = w == null ? "32px" : `${w}px`;
  return <SvgIcon component={OrkanImg} viewBox="0 0 32 32" style={{ height, width }} />;
}

export function getTokenImage(name: string) {
  if (name === "orkan") return getOrkanTokenImage();
  if (name === "sorkan") return getSorkanTokenImage();
}

// TS-REFACTOR-NOTE - Used for:
// AccountSlice.ts, AppSlice.ts
export function setAll(state: any, properties: any) {
  const props = Object.keys(properties);
  if (props) {
    props.forEach(key => state[key] = properties[key]);
  }
}

export function contractForRedeemHelper({
  networkID,
  provider,
}: {
  networkID: number;
  provider: StaticJsonRpcProvider | JsonRpcSigner;
}) {
  return new ethers.Contract(
    addresses[networkID].REDEEM_HELPER_ADDRESS as string,
    RedeemHelperABI,
    provider,
  ) as RedeemHelper;
}

/**
 * returns false if SafetyCheck has fired in this Session. True otherwise
 * @returns boolean
 */
export const shouldTriggerSafetyCheck = () => {
  const _storage = window.sessionStorage;
  const _safetyCheckKey = "-oly-safety";
  // check if sessionStorage item exists for SafetyCheck
  if (!_storage.getItem(_safetyCheckKey)) {
    _storage.setItem(_safetyCheckKey, "true");
    return true;
  }
  return false;
};

/**
 * returns unix timestamp for x minutes ago
 * @param x minutes as a number
 */
export const minutesAgo = (x: number) => {
  const now = new Date().getTime();
  return new Date(now - x * 60000).getTime();
};

/**
 * subtracts two dates for use in 33-together timer
 * param (Date) dateA is the ending date object
 * param (Date) dateB is the current date object
 * returns days, hours, minutes, seconds
 * NOTE: this func previously used parseInt() to convert to whole numbers, however, typescript doesn't like
 * ... using parseInt on number params. It only allows parseInt on string params. So we converted usage to
 * ... Math.trunc which accomplishes the same result as parseInt.
 */
export const subtractDates = (dateA: Date, dateB: Date) => {
  let msA: number = dateA.getTime();
  let msB: number = dateB.getTime();

  let diff: number = msA - msB;

  let days: number = 0;
  if (diff >= 86400000) {
    days = Math.trunc(diff / 86400000);
    diff -= days * 86400000;
  }

  let hours: number = 0;
  if (days || diff >= 3600000) {
    hours = Math.trunc(diff / 3600000);
    diff -= hours * 3600000;
  }

  let minutes: number = 0;
  if (hours || diff >= 60000) {
    minutes = Math.trunc(diff / 60000);
    diff -= minutes * 60000;
  }

  let seconds: number = 0;
  if (minutes || diff >= 1000) {
    seconds = Math.trunc(diff / 1000);
  }
  return {
    days,
    hours,
    minutes,
    seconds,
  };
};
