import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import { BigNumber, BigNumberish, ethers } from 'ethers';
import { RootState } from 'src/store';
import { FuseProxy, IERC20, SOrkanv2, WsORKAN } from 'src/typechain';

import { abi as fuseProxy } from '../abi/FuseProxy.json';
import { abi as ierc20Abi } from '../abi/IERC20.json';
import { abi as sORKANv2 } from '../abi/sOrkanv2.json';
import { abi as wsORKAN } from '../abi/wsORKAN.json';
import { addresses } from '../constants';
import { setAll } from '../helpers';
import { IBaseAddressAsyncThunk, ICalcUserBondDetailsAsyncThunk } from './interfaces';

interface IUserBalances {
  balances: {
    orkan: string;
    sorkan: string;
    fsorkan: string;
    wsorkan: string;
    wsorkanAsSorkan: string;
    pool: string;
  };
}

export const getBalances = createAsyncThunk(
  "account/getBalances",
  async ({ address, networkID, provider }: IBaseAddressAsyncThunk) => {
    const orkanContract = new ethers.Contract(addresses[networkID].ORKAN_ADDRESS as string, ierc20Abi, provider) as IERC20;
    const orkanBalance = await orkanContract.balanceOf(address);
    const sorkanContract = new ethers.Contract(addresses[networkID].SORKAN_ADDRESS as string, ierc20Abi, provider) as IERC20;
    const sorkanBalance = await sorkanContract.balanceOf(address);
    const wsorkanContract = new ethers.Contract(addresses[networkID].WSORKAN_ADDRESS as string, wsORKAN, provider) as WsORKAN;
    const wsorkanBalance = await wsorkanContract.balanceOf(address);
    const wsorkanAsSorkan = await wsorkanContract.wORKANTosORKAN(wsorkanBalance); // NOTE (appleseed): wsorkanAsSorkan is wsORKAN given as a quantity of sORKAN

    let fsorkanBalance = BigNumber.from(0);
    for (const fuseAddressKey of ["FUSE_6_SORKAN", "FUSE_18_SORKAN"]) {
      if (addresses[networkID][fuseAddressKey]) {
        const fsorkanContract = new ethers.Contract(
          addresses[networkID][fuseAddressKey] as string,
          fuseProxy,
          provider.getSigner(),
        ) as FuseProxy;
        // fsorkanContract.signer;
        const balanceOfUnderlying = await fsorkanContract.callStatic.balanceOfUnderlying(address);
        fsorkanBalance = balanceOfUnderlying.add(fsorkanBalance);
      }
    }

    return {
      balances: {
        orkan: ethers.utils.formatUnits(orkanBalance, "gwei"),
        sorkan: ethers.utils.formatUnits(sorkanBalance, "gwei"),
        fsorkan: ethers.utils.formatUnits(fsorkanBalance, "gwei"),
        wsorkan: ethers.utils.formatUnits(wsorkanBalance, "ether"),
        wsorkanAsSorkan: ethers.utils.formatUnits(wsorkanAsSorkan, "gwei"),
        pool: ethers.utils.formatUnits(0, "gwei"),
      },
    };
  },
);

interface IUserAccountDetails {
  staking: {
    orkanStake: number;
    orkanUnstake: number;
  };
  wrapping: {
    sorkanWrap: number;
    wsorkanUnwrap: number;
  };
}

export const loadAccountDetails = createAsyncThunk(
  "account/loadAccountDetails",
  async ({ networkID, provider, address }: IBaseAddressAsyncThunk, { dispatch }) => {
    const orkanContract = new ethers.Contract(addresses[networkID].ORKAN_ADDRESS as string, ierc20Abi, provider) as IERC20;
    const stakeAllowance = await orkanContract.allowance(address, addresses[networkID].STAKING_HELPER_ADDRESS);
    const sorkanContract = new ethers.Contract(addresses[networkID].SORKAN_ADDRESS as string, sORKANv2, provider) as SOrkanv2;
    const unstakeAllowance = await sorkanContract.allowance(address, addresses[networkID].STAKING_ADDRESS);
    // const wrapAllowance = await sorkanContract.allowance(address, addresses[networkID].WSORKAN_ADDRESS);
    // const wsorkanContract = new ethers.Contract(addresses[networkID].WSORKAN_ADDRESS as string, wsORKAN, provider) as WsORKAN;
    // const unwrapAllowance = await wsorkanContract.allowance(address, addresses[networkID].WSORKAN_ADDRESS);
    await dispatch(getBalances({ address, networkID, provider }));

    return {
      staking: {
        orkanStake: +stakeAllowance,
        orkanUnstake: +unstakeAllowance,
      },
      wrapping: {
        orkanWrap: 0,
        orkanUnwrap: 0,
      },
      pooling: {
        // sorkanPool: +poolAllowance,
        sorkanPool: 0,
      },
    };
  },
);

export interface IUserBondDetails {
  allowance: number;
  interestDue: number;
  bondMaturationBlock: number;
  pendingPayout: string; //Payout formatted in gwei.
}
export const calculateUserBondDetails = createAsyncThunk(
  "account/calculateUserBondDetails",
  async ({ address, bond, networkID, provider }: ICalcUserBondDetailsAsyncThunk) => {
    if (!address) {
      return {
        bond: "",
        displayName: "",
        bondIconSvg: "",
        isLP: false,
        allowance: 0,
        balance: "0",
        interestDue: 0,
        bondMaturationBlock: 0,
        pendingPayout: "",
      };
    }
    // dispatch(fetchBondInProgress());

    // Calculate bond details.
    const bondContract = bond.getContractForBond(networkID, provider);
    const reserveContract = bond.getContractForReserve(networkID, provider);

    let pendingPayout, bondMaturationBlock;

    const bondDetails = await bondContract.bondInfo(address);
    let interestDue: BigNumberish = Number(bondDetails.payout.toString()) / Math.pow(10, 9);
    bondMaturationBlock = +bondDetails.vesting + +bondDetails.lastBlock;
    pendingPayout = await bondContract.pendingPayoutFor(address);

    let allowance,
      balance = BigNumber.from(0);
    allowance = await reserveContract.allowance(address, bond.getAddressForBond(networkID));
    balance = await reserveContract.balanceOf(address);
    // formatEthers takes BigNumber => String
    const balanceVal = ethers.utils.formatEther(balance);
    // balanceVal should NOT be converted to a number. it loses decimal precision
    return {
      bond: bond.name,
      displayName: bond.displayName,
      bondIconSvg: bond.bondIconSvg,
      isLP: bond.isLP,
      allowance: Number(allowance.toString()),
      balance: balanceVal,
      interestDue,
      bondMaturationBlock,
      pendingPayout: ethers.utils.formatUnits(pendingPayout, "gwei"),
    };
  },
);

interface IAccountSlice extends IUserAccountDetails, IUserBalances {
  bonds: { [key: string]: IUserBondDetails };
  loading: boolean;
}

const initialState: IAccountSlice = {
  loading: false,
  bonds: {},
  balances: { orkan: "", sorkan: "", wsorkanAsSorkan: "", wsorkan: "", fsorkan: "", pool: "" },
  staking: { orkanStake: 0, orkanUnstake: 0 },
  wrapping: { sorkanWrap: 0, wsorkanUnwrap: 0 },
};

const accountSlice = createSlice({
  name: "account",
  initialState,
  reducers: {
    fetchAccountSuccess(state, action) {
      setAll(state, action.payload);
    },
  },
  extraReducers: builder => {
    builder
      .addCase(loadAccountDetails.pending, state => {
        state.loading = true;
      })
      .addCase(loadAccountDetails.fulfilled, (state, action) => {
        setAll(state, action.payload);
        state.loading = false;
      })
      .addCase(loadAccountDetails.rejected, (state, { error }) => {
        state.loading = false;
      })
      .addCase(getBalances.pending, state => {
        state.loading = true;
      })
      .addCase(getBalances.fulfilled, (state, action) => {
        setAll(state, action.payload);
        state.loading = false;
      })
      .addCase(getBalances.rejected, (state, { error }) => {
        state.loading = false;
        // console.log(error);
      })
      .addCase(calculateUserBondDetails.pending, state => {
        state.loading = true;
      })
      .addCase(calculateUserBondDetails.fulfilled, (state, action) => {
        if (!action.payload) return;
        const bond = action.payload.bond;
        state.bonds[bond] = action.payload;
        state.loading = false;
      })
      .addCase(calculateUserBondDetails.rejected, (state, { error }) => {
        state.loading = false;
        // console.log(error);
      });
  },
});

export default accountSlice.reducer;

export const { fetchAccountSuccess } = accountSlice.actions;

const baseInfo = (state: RootState) => state.account;

export const getAccountState = createSelector(baseInfo, account => account);
