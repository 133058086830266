import { Typography } from "@material-ui/core";

const CardHeader = ({ title, children }) => {
  return (
    <div className="main-title">
      <Typography variant="h2">{title}</Typography>
      {children}
    </div>
  );
};

export default CardHeader;
