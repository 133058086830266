import { Box, Link, Paper, SvgIcon, Typography } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { useCallback, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { bondsViewEnabled, dashboardViewEnabled, stakeViewEnabled, wrapViewEnabled } from 'src/helpers/envHelper';
import useTheme from 'src/hooks/useTheme';
import { useAddress, useWeb3Context } from 'src/hooks/web3Context';
import { ReactComponent as BondIcon } from '../../assets/icons/bond.svg';
import { ReactComponent as DashboardIcon } from '../../assets/icons/dashboard.svg';
import { ReactComponent as MoonIcon } from '../../assets/icons/moonpot.svg';
import { ReactComponent as StakeIcon } from '../../assets/icons/stake.svg';
import { ReactComponent as VaultIcon } from '../../assets/icons/vault.svg';
import { ReactComponent as WrapIcon } from '../../assets/icons/wrap.svg';
import OrkanLogoDark from '../../assets/orkan-logo-dark.svg';
import OrkanLogoLight from '../../assets/orkan-logo-light.svg';
import { shorten, trim } from '../../helpers';
import useBonds from '../../hooks/Bonds';
import externalUrls from './externalUrls';
import './sidebar.scss';

function NavContent() {
  const [isActive] = useState();
  const address = useAddress();
  const { chainID } = useWeb3Context();
  const { bonds } = useBonds(chainID);
  const [theme] = useTheme();

  const checkPage = useCallback((match, location, page) => {
    const currentPath = location.pathname.replace("/", "");
    if (currentPath.indexOf("dashboard") >= 0 && page === "dashboard") {
      return true;
    }
    if (currentPath.indexOf("stake") >= 0 && page === "stake") {
      return true;
    }
    if (currentPath.indexOf("presale") >= 0 && page === "presale") {
      return true;
    }
    if ((currentPath.indexOf("bonds") >= 0 || currentPath.indexOf("choose_bond") >= 0) && page === "bonds") {
      return true;
    }
    return false;
  }, []);

  return (
    <Paper className="dapp-sidebar">
      <Box className="dapp-sidebar-inner" display="flex" justifyContent="space-between" flexDirection="column">
        <div className="dapp-menu-top">
          <Box className="branding-header">
            <Link className="nav-sidebar-logo" href="https://orkan.finance" target="_blank">
              {window.innerWidth > 768 && <img src={theme === "light" ? OrkanLogoDark : OrkanLogoLight} />}
            </Link>

            {address && (
              <div className="wallet-link">
                <Link href={`https://etherscan.io/address/${address}`} target="_blank">
                  {shorten(address)}
                </Link>
              </div>
            )}
          </Box>

          <div className="dapp-menu-links">
            <div className="dapp-nav" id="navbarNav">
              {dashboardViewEnabled &&
                <Link
                  component={NavLink}
                  id="dash-nav"
                  to="/dashboard"
                  isActive={(match, location) => {
                    return checkPage(match, location, "dashboard");
                  }}
                  className={`button-dapp-menu ${isActive ? "active" : ""}`}
                >
                  <Typography variant="h6">
                    <SvgIcon color="primary" component={DashboardIcon} />
                    Dashboard
                  </Typography>
                </Link>
              }

              {stakeViewEnabled &&
                <Link
                  component={NavLink}
                  id="stake-nav"
                  to="/stake"
                  isActive={(match, location) => {
                    return checkPage(match, location, "stake");
                  }}
                  className={`button-dapp-menu ${isActive ? "active" : ""}`}
                >
                  <Typography variant="h6">
                    <SvgIcon color="primary" component={StakeIcon} />
                    Stake
                  </Typography>
                </Link>
              }

              {wrapViewEnabled &&
                <Link
                  component={NavLink}
                  id="stake-nav"
                  to="/wrap"
                  isActive={(match, location) => {
                    return checkPage(match, location, "wrap");
                  }}
                  className={`button-dapp-menu ${isActive ? "active" : ""}`}
                >
                  <Typography variant="h6">
                    <SvgIcon color="primary" component={StakeIcon} />
                    Wrap
                  </Typography>
                </Link>
              }

              {bondsViewEnabled &&
                <Link
                  component={NavLink}
                  id="bond-nav"
                  to="/bonds"
                  isActive={(match, location) => {
                    return checkPage(match, location, "bonds");
                  }}
                  className={`button-dapp-menu ${isActive ? "active" : ""}`}>
                  <Typography variant="h6">
                    <SvgIcon color="primary" component={StakeIcon} />
                    Bond
                  </Typography>
                </Link>
              }

              <a
                href="https://app.beefy.finance/#/fantom/vault/spirit-orkan-ftm"
                target='_blank'
                className={`MuiTypography-root MuiLink-root MuiLink-underlineNone button-dapp-menu  MuiTypography-colorPrimary button-dapp-menu ${isActive ? "active" : ""}`}>
                <Typography variant="h6">
                  <SvgIcon color="primary" component={StakeIcon} />
                  Vaults
                </Typography>
              </a>

              <a
                href="https://play.moonpot.com/#/pot/orkan-ftm"
                target='_blank'
                className={`MuiTypography-root MuiLink-root MuiLink-underlineNone button-dapp-menu  MuiTypography-colorPrimary button-dapp-menu ${isActive ? "active" : ""}`}>
                <Typography variant="h6">
                  <SvgIcon color="primary" component={StakeIcon} />
                  Moonpot
                </Typography>
              </a>

              {bondsViewEnabled &&
                <div className="dapp-menu-data discounts">
                  <div className="bond-discounts">
                    <Typography variant="body2">Bond discounts</Typography>
                    {bonds.map((bond, i) => (
                      <Link component={NavLink} to={`/bonds/${bond.name}`} key={i} className={"bond"}>
                        {!bond.bondDiscount ? (
                          <Skeleton variant="text" width={"150px"} />
                        ) : (
                          <Typography variant="body2">
                            {bond.displayName}

                            <span className="bond-pair-roi">
                              {!bond.isAvailable[chainID]
                                ? "Sold Out"
                                : `${bond.bondDiscount && trim(bond.bondDiscount * 100, 2)}%`}
                            </span>
                          </Typography>
                        )}
                      </Link>
                    ))}
                  </div>
                </div>
              }
            </div>
          </div>
        </div>
        <Box className="dapp-menu-bottom" display="flex" justifyContent="space-between" flexDirection="column">
          <div className="dapp-menu-external-links">
            {Object.keys(externalUrls).map((link, i) => {
              return (
                <Link key={i} href={`${externalUrls[link].url}`} target="_blank">
                  <Typography variant="h6">{externalUrls[link].icon}</Typography>
                  <Typography variant="h6">{externalUrls[link].title}</Typography>
                </Link>
              );
            })}
          </div>
          <div className="dapp-menu-social">
            {/* <Social /> */}
          </div>
        </Box>
      </Box>
    </Paper>
  );
}

export default NavContent;
