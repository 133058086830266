import OrkanEthImg from "src/assets/orkan-fantom-logo.svg";
import LQDImg from "src/assets/liquid-logo.svg"
import SpiritImg from "src/assets/spirit-logo.svg"
import VBTCImg from "src/assets/vbtc-logo.svg"
import miticushquiImg from "src/assets/miticushqui.jpg"
import WBTCImg from "src/assets/wbtc-logo.svg"

import { abi as PairABI } from "src/abi/PairContract.json";
import { abi as WBTCBondContract } from "src/abi/bonds/WBTCBondContract.json";
import { abi as ReserveWBTCContract } from "src/abi/reserves/ReserveWBTCContract.json";
import { abi as SpiritBondContract } from "src/abi/bonds/SpiritBondContract.json";
import { abi as ReserveSpiritContract } from "src/abi/reserves/ReserveSpiritContract.json";
import { abi as LQDRBondContract } from "src/abi/bonds/LQDRBondContract.json";
import { abi as ReserveLQDRContract } from "src/abi/reserves/ReserveLQDRContract.json";;
import { abi as MitiCushquiBondContract } from "src/abi/bonds/MitiCushquiBondContract.json";
import { abi as ReserveMitiCushquiContract } from "src/abi/reserves/ReserveMitiCushquiContract.json";
import { abi as VBTCBondContract } from "src/abi/bonds/VBTCBondContract.json";
import { abi as ReserveVBTCContract } from "src/abi/reserves/ReserveVBTCContract.json";
import { abi as OrkanFtmBondContract } from "src/abi/bonds/OrkanFtmBondContract.json";
import { abi as OrkanMiticushquiBondContract } from "src/abi/bonds/OrkanMiticushquiBondContract.json";
import { abi as ReserveOrkanMiticushquiContract } from "src/abi/reserves/ReserveOrkanMiticushquiContract.json";
import { abi as ReserveOrkanFTMContract } from "src/abi/reserves/ReserveOrkanFTMContract.json";
import { BondType, CustomBond, LPBond, NetworkID, StableBond } from "src/lib/Bond";
import { abi as ReserveUsdcFTMContract } from "src/abi/reserves/ReserveUsdcFTMContract.json";
import { ethers } from "ethers";
import { PairContract } from "src/typechain";
import { getBondRate, getChainBaseToken } from ".";
import { addresses } from "src/constants";
import BigNumber from "bignumber.js";

const spiritReserveAddress = "0x5Cc61A78F164885776AA610fb0FE1257df78E59B";
const spiritPairAddress = "0x30748322B6E34545DBe0788C421886AEB5297789";

const lqdrReserveAddress = "0x10b620b2dbac4faa7d7ffd71da486f5d44cd86f9";
const lqdrPairAddress = "0x4fe6f19031239f105f753d1df8a0d24857d0caa2";

const vbtcReserveAddress = "0x9049198f6b21ACf1E050cFCf36A6879a07b0bBe4";
const vbtcPairAddress = "0xe322deb4d7bc0440943914680efd510339692743";

const wbtcReserveAddress = "0x321162Cd933E2Be498Cd2267a90534A804051b11";
const wbtcPairAddress = "0x279b2c897737a50405ed2091694f225d83f2d3ba";

const orkanFtmPairAddress = "0x5A6880d3e9d715BEf5848c9749cea5F23a982A75";

const mitiCushquiReserveAddress = "0x76550CCA73f1166C05c56D61B899C5ba4d257220";
const mitiCushquiOrkanReserveAddress = "0x39518cd8f67ed2d740b6be05b824a3be040f026e";

export const usdc_ftm = new LPBond({
  name: "usdc-weth",
  displayName: "USDC_FTM LP",
  bondToken: "USDC-FTM",
  usesStandardBondingCalculator: true,
  isAvailable: { [NetworkID.Mainnet]: true, [NetworkID.Testnet]: false },
  bondIconSvg: OrkanEthImg,
  bondContractABI: OrkanFtmBondContract,
  reserveContract: ReserveUsdcFTMContract,
  networkAddrs: {
    [NetworkID.Mainnet]: {
      bondAddress: "",
      reserveAddress: "0xe7e90f5a767406eff87fdad7eb07ef407922ec1d",
    },
    [NetworkID.Testnet]: {
      bondAddress: "",
      reserveAddress: "",
    },
  },
  lpUrl: "",
});

export const orkan_ftm = new CustomBond({
  name: "orkan_ftm",
  displayName: "ORKAN FTM",
  lpUrl: "",
  bondType: BondType.LP,
  usesStandardBondingCalculator: false,
  bondToken: "ORKAN FTM",
  isAvailable: { [NetworkID.Mainnet]: true, [NetworkID.Testnet]: false },
  bondIconSvg: OrkanEthImg,
  bondContractABI: OrkanFtmBondContract,
  reserveContract: ReserveOrkanFTMContract,
  networkAddrs: {
    [NetworkID.Mainnet]: {
      bondAddress: "0x62cb7cE86F44859146b37e529894BC2527a54178",
      reserveAddress: "0x5A6880d3e9d715BEf5848c9749cea5F23a982A75",
    },
    [NetworkID.Testnet]: {
      bondAddress: "",
      reserveAddress: "",
    },
  },
  newFloor: false,
  customBondRateFunc: async function (this: CustomBond, networkID, provider) {
    return 0;
  },
  customTreasuryBalanceFunc: async function (this: CustomBond, networkID, provider) {
    const reserveContract = this.getContractForReserve(networkID, provider);
    const reserveSupply = await reserveContract.totalSupply();
    const reserveSupplyReadable = new BigNumber(reserveSupply.toString()).div(1e18);
    const reserves = await reserveContract.getReserves();
    const ftmReserve = reserves[0]; // This is the reserve amount of WFTM
    const ftmReserveReadable = new BigNumber(ftmReserve.toString()).div(1e18);
    const ftmPrice = await getChainBaseToken(networkID, provider);
    const reserveFtmInDollars = new BigNumber(ftmReserveReadable).times(ftmPrice);
    const totalValue = new BigNumber(reserveFtmInDollars).times(2);
    const lpPrice = new BigNumber(totalValue).div(reserveSupplyReadable.toString());
    const amountInContract = await reserveContract.balanceOf(addresses[networkID].TREASURY_ADDRESS);
    const amountInContractReadable = new BigNumber(amountInContract.toString()).div(1e18);
    return new BigNumber(amountInContractReadable).times(lpPrice).toNumber();
  },
});

export const orkan_ftm_2 = new CustomBond({
  name: "orkan_ftm_v2",
  displayName: "ORKAN FTM (30 Days)",
  lpUrl: "",
  bondType: BondType.LP,
  usesStandardBondingCalculator: false,
  bondToken: "ORKAN FTM",
  isAvailable: { [NetworkID.Mainnet]: true, [NetworkID.Testnet]: false },
  bondIconSvg: OrkanEthImg,
  bondContractABI: OrkanFtmBondContract,
  reserveContract: ReserveOrkanFTMContract,
  networkAddrs: {
    [NetworkID.Mainnet]: {
      bondAddress: "0x8272546872aFaCf8229108e80B01ebE683C98cd1",
      reserveAddress: "0x5A6880d3e9d715BEf5848c9749cea5F23a982A75",
    },
    [NetworkID.Testnet]: {
      bondAddress: "",
      reserveAddress: "",
    },
  },
  newFloor: false,
  customBondRateFunc: async function (this: CustomBond, networkID, provider) {
    return 0;
  },
  dontAddTreasuryBalance: true,
  customTreasuryBalanceFunc: async function (this: CustomBond, networkID, provider) {
    const reserveContract = this.getContractForReserve(networkID, provider);
    const reserveSupply = await reserveContract.totalSupply();
    const reserveSupplyReadable = new BigNumber(reserveSupply.toString()).div(1e18);
    const reserves = await reserveContract.getReserves();
    const ftmReserve = reserves[0]; // This is the reserve amount of WFTM
    const ftmReserveReadable = new BigNumber(ftmReserve.toString()).div(1e18);
    const ftmPrice = await getChainBaseToken(networkID, provider);
    const reserveFtmInDollars = new BigNumber(ftmReserveReadable).times(ftmPrice);
    const totalValue = new BigNumber(reserveFtmInDollars).times(2);
    const lpPrice = new BigNumber(totalValue).div(reserveSupplyReadable.toString());
    const amountInContract = await reserveContract.balanceOf(addresses[networkID].TREASURY_ADDRESS);
    const amountInContractReadable = new BigNumber(amountInContract.toString()).div(1e18);
    return new BigNumber(amountInContractReadable).times(lpPrice).toNumber();
  },
});

export const VBTC = new CustomBond({
  name: "vbtc",
  displayName: "VBTC",
  lpUrl: "",
  bondType: BondType.StableAsset,
  usesStandardBondingCalculator: true,
  bondToken: "VBTC",
  isAvailable: { [NetworkID.Mainnet]: true, [NetworkID.Testnet]: false },
  bondIconSvg: VBTCImg,
  bondContractABI: VBTCBondContract,
  reserveContract: ReserveVBTCContract, // The Standard ierc20Abi since they're normal tokens
  networkAddrs: {
    [NetworkID.Mainnet]: {
      bondAddress: "0x616747464Ad3236eA1E219490E6a5CaA72f8420b",
      reserveAddress: vbtcReserveAddress,
    },
    [NetworkID.Testnet]: {
      bondAddress: "",
      reserveAddress: "",
    },
  },
  newFloor: true,
  customBondRateFunc: async function (this: CustomBond, networkID, provider) {
    const newRate = await getBondRate(vbtcPairAddress, 10000, networkID, false, false);
    return newRate;
  },
  customTreasuryBalanceFunc: async function (this: CustomBond, networkID, provider) {
    const lpAddress = vbtcPairAddress;
    const contract = new ethers.Contract(lpAddress, PairABI, provider) as PairContract;
    const getReserves = await contract.getReserves();
    const reserve0 = new BigNumber(getReserves[0].toString()).div(1e18);
    const reserve1 = new BigNumber(getReserves[1].toString()).div(1e18);
    const vbtcPriceInFTM = Number(reserve0.div(reserve1));
    const ftmPrice = await getChainBaseToken(networkID, provider);
    const vbtcPriceInDollars = vbtcPriceInFTM * ftmPrice;
    const token = this.getContractForReserve(networkID, provider);
    const amountInContract = await token.balanceOf(addresses[networkID].TREASURY_ADDRESS);
    let amountInContractInWei = new BigNumber(amountInContract.toString()).div(1e18);
    return Number(amountInContractInWei) * vbtcPriceInDollars;
  },
});

// export const MitiCushqui = new StableBond({
//   name: "MitiCushqui",
//   displayName: "MitiCushqui (M)",
//   bondToken: "MitiCushqui (M)",
//   useStandardBondingCalculator: true,
//   newFloor: false,
//   bondIconSvg: miticushquiImg,
//   bondContractABI: MitiCushquiBondContract,
//   isAvailable: { [NetworkID.Mainnet]: true, [NetworkID.Testnet]: false },
//   networkAddrs: {
//     [NetworkID.Mainnet]: {
//       bondAddress: "0xFE3e32A421643f067752C50C7c45D34487964072",
//       reserveAddress: mitiCushquiReserveAddress,
//     },
//     [NetworkID.Testnet]: {
//       bondAddress: "",
//       reserveAddress: "",
//     },
//   }
// });

export const MitiCushquiOrkan = new CustomBond({
  name: "miti_orkan",
  displayName: "ORKAN / MitiCushqui",
  lpUrl: "",
  bondType: BondType.LP,
  usesStandardBondingCalculator: false,
  divideByOrkan: true,
  bondToken: "ORKAN M",
  isAvailable: { [NetworkID.Mainnet]: true, [NetworkID.Testnet]: false },
  bondIconSvg: miticushquiImg,
  bondContractABI: OrkanMiticushquiBondContract,
  reserveContract: ReserveOrkanMiticushquiContract,
  networkAddrs: {
    [NetworkID.Mainnet]: {
      bondAddress: "0x7F9dFcf67c0aB931eFe31157462836C0D9b8e55C",
      reserveAddress: mitiCushquiOrkanReserveAddress,
    },
    [NetworkID.Testnet]: {
      bondAddress: "",
      reserveAddress: "",
    },
  },
  newFloor: false,
  customBondRateFunc: async function (this: CustomBond, networkID, provider) {
    return 0;
  },
  customTreasuryBalanceFunc: async function (this: CustomBond, networkID, provider) {
    const reserveContract = this.getContractForReserve(networkID, provider);
    const reserveSupply = await reserveContract.totalSupply();
    const reserveSupplyReadable = new BigNumber(reserveSupply.toString()).div(1e18);
    const reserves = await reserveContract.getReserves();
    const miticushquiReserve = reserves[0]; // This is the reserve amount of Miticushqui
    const miticushquiReserveReadable = new BigNumber(miticushquiReserve.toString()).div(1e18);
    const ftmPrice = await getChainBaseToken(networkID, provider);
    const reserveFtmInDollars = new BigNumber(miticushquiReserveReadable).times(ftmPrice);
    const totalValue = new BigNumber(reserveFtmInDollars).times(2);
    const lpPrice = new BigNumber(totalValue).div(reserveSupplyReadable.toString());
    const amountInContract = await reserveContract.balanceOf(addresses[networkID].TREASURY_ADDRESS);
    const amountInContractReadable = new BigNumber(amountInContract.toString()).div(1e18);
    return new BigNumber(amountInContractReadable).times(lpPrice).toNumber();
  },
});

// HOW TO ADD A NEW BOND:
// LPBond = LIQUIDITY POOL BONDS
// CustomBond = For non / stable coins bonds
// StableBond = Stable bonds etc..
// export const allBonds = [orkan_ftm, VBTC, spirit, LQDR, WBTC];
export const allBonds = [orkan_ftm, orkan_ftm_2, VBTC, MitiCushquiOrkan];
export const allBondsMap = allBonds.reduce((prevVal, bond) => { return { ...prevVal, [bond.name]: bond }; }, {});

export default allBonds;
