import { StaticJsonRpcProvider } from "@ethersproject/providers";
import { NetworkID } from "src/lib/Bond";
import { abi as StandardBondCalcContractABI } from "src/abi/StandardBondCalcContract.json";
import { abi as NoneStandardBondCalcContractABI } from "src/abi/NoneStandardBondCalcContract.json";
import { ethers } from "ethers";
import { addresses } from "src/constants";
import { BondCalcContract } from "src/typechain/BondCalcContract";
import { NoneStandardBondCalcContract } from "src/typechain/NoneStandardBondCalcContract";

export function getStandardBondCalculator(networkID: NetworkID, provider: StaticJsonRpcProvider) {
  return new ethers.Contract(
    addresses[networkID].STANDARD_BONDINGCALC_ADDRESS as string,
    StandardBondCalcContractABI,
    provider,
  ) as BondCalcContract;
}

export function getNoneStandardBondCalculator(networkID: NetworkID, provider: StaticJsonRpcProvider) {
  return new ethers.Contract(
    addresses[networkID].NON_STANDARD_BONDINGCALC_ADDRESS as string,
    NoneStandardBondCalcContractABI,
    provider,
  ) as NoneStandardBondCalcContract;
}
