import '../Stake/stake.scss';
import './wrap.scss';

import {
  Box,
  Button,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  makeStyles,
  OutlinedInput,
  Paper,
  Tab,
  Tabs,
  Typography,
  Zoom,
} from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Skeleton } from '@material-ui/lab';
import { ethers } from 'ethers';
import { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useWeb3Context } from 'src/hooks/web3Context';
import { isPendingTxn, txnButtonText } from 'src/slices/PendingTxnsSlice';

import CodeRebaseIcon from '../../assets/icons/code-rebase.svg';
import TagIcon from '../../assets/icons/tag.svg';
import TabPanel from '../../components/TabPanel';
import { formatCurrency, getOrkanTokenImage, getTokenImage, trim } from '../../helpers';
import { error } from '../../slices/MessagesSlice';
import { changeApproval, changeWrap } from '../../slices/WrapThunk';

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const sOrkanImg = getTokenImage("sorkan");
const orkanImg = getOrkanTokenImage(16, 16);

const useStyles = makeStyles(theme => ({
  textHighlight: {
    color: theme.palette.highlight,
  },
}));

function Wrap() {
  const dispatch = useDispatch();
  const { provider, address, connect, chainID } = useWeb3Context();
  const isVerySmallScreen = useMediaQuery("(max-width: 420px)");

  const [zoomed, setZoomed] = useState(false);
  const [view, setView] = useState(0);
  const [quantity, setQuantity] = useState("");
  const classes = useStyles();

  const isAppLoading = useSelector(state => state.app.loading);
  const currentIndex = useSelector(state => {
    return state.app.currentIndex;
  });

  const sOrkanPrice = useSelector(state => {
    return state.app.marketPrice;
  });

  const wsOrkanPrice = useSelector(state => {
    return state.app.marketPrice * state.app.currentIndex;
  });

  const sorkanBalance = useSelector(state => {
    return state.account.balances && state.account.balances.sorkan;
  });
  const wsorkanBalance = useSelector(state => {
    return state.account.balances && state.account.balances.wsorkan;
  });
  const wrapAllowance = useSelector(state => {
    return state.account.wrapping && state.account.wrapping.orkanWrap;
  });
  const unwrapAllowance = useSelector(state => {
    return state.account.wrapping && state.account.wrapping.orkanUnwrap;
  });

  const pendingTransactions = useSelector(state => {
    return state.pendingTransactions;
  });

  const setMax = () => {
    if (view === 0) {
      setQuantity(sorkanBalance);
    } else {
      setQuantity(wsorkanBalance);
    }
  };

  const onSeekApproval = async token => {
    await dispatch(changeApproval({ address, token, provider, networkID: chainID }));
  };

  const onChangeWrap = async action => {
    // eslint-disable-next-line no-restricted-globals
    if (isNaN(quantity) || Number(quantity) === 0 || quantity === "") {
      // eslint-disable-next-line no-alert
      return dispatch(error("Please enter a value!"));
    }

    // 1st catch if quantity > balance
    if (
      action === "wrap" &&
      ethers.utils.parseUnits(quantity, "gwei").gt(ethers.utils.parseUnits(sorkanBalance, "gwei"))
    ) {
      return dispatch(error("You cannot wrap more than your sORKAN balance."));
    }

    if (
      action === "unwrap" &&
      ethers.utils.parseUnits(quantity, "ether").gt(ethers.utils.parseUnits(wsorkanBalance, "ether"))
    ) {
      return dispatch(error("You cannot unwrap more than your wsORKAN balance."));
    }

    await dispatch(changeWrap({ address, action, value: quantity.toString(), provider, networkID: chainID }));
  };

  const hasAllowance = useCallback(
    token => {
      if (token === "sorkan") return wrapAllowance > 0;
      if (token === "wsorkan") return wrapAllowance > 0;
      return 0;
    },
    [wrapAllowance, unwrapAllowance],
  );

  const isAllowanceDataLoading = (wrapAllowance == null && view === 0) || (unwrapAllowance == null && view === 1);

  const isUnwrap = view === 1;
  const convertedQuantity = isUnwrap ? (quantity * wsOrkanPrice) / sOrkanPrice : (quantity * sOrkanPrice) / wsOrkanPrice;

  let modalButton = [];

  modalButton.push(
    <Button variant="contained" color="primary" className="connect-button" onClick={connect} key={1}>
      Connect Wallet
    </Button>,
  );

  const changeView = (event, newView) => {
    setView(newView);
  };

  return (
    <div id="stake-view" className="wrap-view">
      <div class="page-wrapper-bg"></div>
      <Zoom in={true} onEntered={() => setZoomed(true)}>
        <div class="main-container">
          <div class="main-title">
            <h1>Wrap / Unwrap</h1>
          </div>
          {/* ---------------- */}
          <Grid item>
            <div className="stake-top-metrics">
              <Grid container spacing={2} alignItems="flex-end">
                <Grid item xs={12} sm={4} md={4} lg={4}>
                  <div className="stake-block stake-apy">
                    <div className="stake-icon"><span><img src={TagIcon} /></span></div>
                    <div className="stake-content">
                      <Typography variant="h5" color="textSecondary">
                        sORKAN Price
                      </Typography>
                      <Typography variant="h4">
                        {sOrkanPrice ? formatCurrency(sOrkanPrice, 2) : <Skeleton width="150px" />}
                      </Typography>
                    </div>
                  </div>
                </Grid>

                <Grid item xs={12} sm={4} md={4} lg={4}>
                  <div className="stake-block stake-index">
                    <div className="stake-icon"><span><img src={TagIcon} /></span></div>
                    <div className="stake-content">
                      <Typography variant="h5" color="textSecondary">
                        wsORKAN Price
                      </Typography>
                      <Typography variant="h4">
                        {wsOrkanPrice ? formatCurrency(wsOrkanPrice, 2) : <Skeleton width="150px" />}
                      </Typography>
                    </div>
                  </div>
                </Grid>

                <Grid item xs={12} sm={4} md={4} lg={4}>
                  <div className="stake-block stake-tvl">
                    <div className="stake-icon"><span><img src={CodeRebaseIcon} /></span></div>
                    <div className="stake-content">
                      <Typography variant="h5" color="textSecondary">
                        Current Index
                      </Typography>
                      <Typography variant="h4">
                        {currentIndex ? <>{trim(currentIndex, 0)}</> : <Skeleton width="150px" />}
                      </Typography>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </div>
          </Grid>
          {/* ---------------- */}
          <Paper className={`orkan-card`}>
            <Grid container direction="column" spacing={2}>
              <div className="staking-area">
                {!address ? (
                  <div className="stake-wallet-notification">
                    <div className="wallet-menu" id="wallet-menu">
                      {modalButton}
                    </div>
                    <Typography variant="h6">Connect your wallet to wrap sORKAN</Typography>
                  </div>
                ) : (
                  <>
                    <Box className="stake-action-area">
                      <Tabs
                        key={String(zoomed)}
                        value={view}
                        textColor="primary"
                        indicatorColor="primary"
                        className="stake-tab-buttons"
                        onChange={changeView}
                        aria-label="stake tabs"
                      >
                        <Tab label="Wrap" {...a11yProps(0)} />
                        <Tab label="Unwrap" {...a11yProps(1)} />
                      </Tabs>
                      <Box className="stake-action-row " display="flex" alignItems="center" style={{ paddingBottom: 0 }}>
                        {address && !isAllowanceDataLoading ? (
                          !hasAllowance("sorkan") && view === 0 ? (
                            <Box className="help-text">
                              <Typography variant="body1" className="stake-note" color="textSecondary">
                                {view === 0 && (
                                  <>
                                    First time wrapping <b>sORKAN</b>?
                                    <br />
                                    Please approve Strudel Dao to use your <b>sORKAN</b> for wrapping.
                                  </>
                                )}
                              </Typography>
                            </Box>
                          ) : (
                            <FormControl className="orkan-input" variant="outlined" color="primary">
                              <InputLabel htmlFor="amount-input"></InputLabel>
                              <OutlinedInput
                                id="amount-input"
                                type="number"
                                placeholder="Enter an amount"
                                className="stake-input"
                                value={quantity}
                                onChange={e => setQuantity(e.target.value)}
                                labelWidth={0}
                                endAdornment={
                                  <InputAdornment position="end">
                                    <Button variant="text" onClick={setMax} color="inherit">
                                      Max
                                    </Button>
                                  </InputAdornment>
                                }
                              />
                            </FormControl>
                          )
                        ) : (
                          <Skeleton width="150px" />
                        )}

                        <TabPanel value={view} index={0} className="stake-tab-panel">
                          {address && hasAllowance("sorkan") ? (
                            <Button
                              className="stake-button"
                              variant="contained"
                              color="primary"
                              disabled={isPendingTxn(pendingTransactions, "wrapping")}
                              onClick={() => {
                                onChangeWrap("wrap");
                              }}
                            >
                              {txnButtonText(pendingTransactions, "wrapping", "Wrap sORKAN")}
                            </Button>
                          ) : (
                            <Button
                              className="stake-button"
                              variant="contained"
                              color="primary"
                              disabled={isPendingTxn(pendingTransactions, "approve_wrapping")}
                              onClick={() => {
                                onSeekApproval("sorkan");
                              }}
                            >
                              {txnButtonText(pendingTransactions, "approve_wrapping", "Approve")}
                            </Button>
                          )}
                        </TabPanel>

                        <TabPanel value={view} index={1} className="stake-tab-panel">
                          <Button
                            className="stake-button"
                            variant="contained"
                            color="primary"
                            disabled={isPendingTxn(pendingTransactions, "unwrapping")}
                            onClick={() => {
                              onChangeWrap("unwrap");
                            }}
                          >
                            {txnButtonText(pendingTransactions, "unwrapping", "Unwrap sORKAN")}
                          </Button>
                        </TabPanel>
                      </Box>

                      {quantity && (
                        <Box padding={1}>
                          <Typography variant="body2" className={classes.textHighlight}>
                            {isUnwrap
                              ? `Unwrapping ${quantity} wsORKAN will result in ${trim(convertedQuantity, 4)} sORKAN`
                              : `Wrapping ${quantity} sORKAN will result in ${trim(convertedQuantity, 4)} wsORKAN`}
                          </Typography>
                        </Box>
                      )}
                    </Box>

                    <div className={`stake-user-data`}>
                      <div className="data-row">
                        <Typography variant="body1">Wrappable Balance</Typography>
                        <Typography variant="body1">
                          {isAppLoading ? <Skeleton width="80px" /> : <>{trim(sorkanBalance, 4)} sORKAN</>}
                        </Typography>
                      </div>
                      <div className="data-row">
                        <Typography variant="body1">Unwrappable Balance</Typography>
                        <Typography variant="body1">
                          {isAppLoading ? <Skeleton width="80px" /> : <>{trim(wsorkanBalance, 4)} wsORKAN</>}
                        </Typography>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </Grid>
          </Paper>
        </div>
      </Zoom>
    </div>
  );
}

export default Wrap;
