import { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Button,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  Link,
  OutlinedInput,
  Paper,
  Tab,
  Tabs,
  Typography,
  Zoom,
  Divider,
} from "@material-ui/core";
import { trim } from "../../helpers";
import { changeApproval, changeStake } from "../../slices/StakeThunk";
import { useWeb3Context } from "src/hooks/web3Context";
import { isPendingTxn, txnButtonText } from "src/slices/PendingTxnsSlice";
import { Skeleton } from "@material-ui/lab";
import { error } from "../../slices/MessagesSlice";
import { ethers } from "ethers";
import { BigNumber } from "bignumber.js";

import NewReleases from "@material-ui/icons/NewReleases";
import RebaseTimer from "../../components/RebaseTimer/RebaseTimer";
import TabPanel from "../../components/TabPanel";
import CodeRebaseIcon from "../../assets/icons/code-rebase.svg";
import PiggyBankIcon from "../../assets/icons/piggy-bank.svg";
import PercentageIcon from "../../assets/icons/percentage.svg";

import "./stake.scss";

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function Stake() {
  const dispatch = useDispatch();
  const { provider, address, connect, chainID } = useWeb3Context();

  const [zoomed, setZoomed] = useState(false);
  const [view, setView] = useState(0);
  const [quantity, setQuantity] = useState("");

  const isAppLoading = useSelector(state => state.app.loading);
  const currentIndex = useSelector(state => {
    return state.app.currentIndex;
  });
  const fiveDayRate = useSelector(state => {
    return state.app.fiveDayRate;
  });
  const orkanBalance = useSelector(state => {
    return state.account.balances && state.account.balances.orkan;
  });
  const oldSorkanBalance = useSelector(state => {
    return state.account.balances && state.account.balances.oldsorkan;
  });
  const sorkanBalance = useSelector(state => {
    return state.account.balances && state.account.balances.sorkan;
  });
  const stakeAllowance = useSelector(state => {
    return state.account.staking && state.account.staking.orkanStake;
  });
  const unstakeAllowance = useSelector(state => {
    return state.account.staking && state.account.staking.orkanUnstake;
  });
  const stakingRebase = useSelector(state => {
    return state.app.stakingRebase;
  });
  const stakingAPY = useSelector(state => {
    return state.app.stakingAPY;
  });
  const stakingTVL = useSelector(state => {
    return state.app.stakingTVL;
  });

  const pendingTransactions = useSelector(state => {
    return state.pendingTransactions;
  });

  const setMax = () => {
    if (view === 0) {
      setQuantity(orkanBalance);
    } else {
      setQuantity(sorkanBalance);
    }
  };

  const onSeekApproval = async token => {
    dispatch(changeApproval({ address, token, provider, networkID: chainID }));
  };

  const onChangeStake = async action => {
    // eslint-disable-next-line no-restricted-globals
    if (isNaN(quantity) || quantity === 0 || quantity === "") {
      // eslint-disable-next-line no-alert
      return dispatch(error("Please enter a value!"));
    }

    // 1st catch if quantity > balance
    let gweiValue = ethers.utils.parseUnits(quantity, "gwei");
    if (action === "stake" && gweiValue.gt(ethers.utils.parseUnits(orkanBalance, "gwei"))) {
      return dispatch(error("You cannot stake more than your ORKAN balance."));
    }

    if (action === "unstake" && gweiValue.gt(ethers.utils.parseUnits(sorkanBalance, "gwei"))) {
      return dispatch(error("You cannot unstake more than your sORKAN balance."));
    }

    dispatch(changeStake({ address, action, value: quantity.toString(), provider, networkID: chainID }));
  };

  const hasAllowance = useCallback(token => {
    if (token === "orkan") return stakeAllowance > 0;
    if (token === "sorkan") return unstakeAllowance > 0;
    return 0;
  },
    [stakeAllowance, unstakeAllowance],
  );

  const isAllowanceDataLoading = (stakeAllowance == null && view === 0) || (unstakeAllowance == null && view === 1);

  let modalButton = [];

  const showValue = (value, checkValueToHigh, fractionDigits) => {
    if (new BigNumber(value).isEqualTo(0) || !isFinite(value)) {
      return (<>0</>)
    } else if (new BigNumber(value).gt(10000000) && checkValueToHigh) {
      return (<>-</>)
    } else if (new BigNumber(value).gt(0)) {
      return (<>{new Intl.NumberFormat("en-US", { maximumFractionDigits: fractionDigits, minimumFractionDigits: fractionDigits }).format(value)}</>)
    } else (
      <Skeleton width="150px" />
    )
  }

  modalButton.push(
    <Button variant="contained" color="primary" className="connect-button" onClick={connect} key={1}>
      Connect Wallet
    </Button>,
  );

  const changeView = (event, newView) => {
    setView(newView);
  };

  const trimmedBalance = Number([sorkanBalance]
    .filter(Boolean)
    .map(balance => Number(balance))
    .reduce((a, b) => a + b, 0)
    .toFixed(4),
  );
  const stakingRebasePercentage = trim(stakingRebase * 100, 4);
  const nextRewardValue = trim((stakingRebasePercentage / 100) * trimmedBalance, 4);
  const trimmedStakingAPY = trim(stakingAPY * 100, 1);

  return (
    <div id="stake-view">
      <div className="page-wrapper-bg"></div>
      <Zoom in={true} onEntered={() => setZoomed(true)}>
        <div class="main-container">
          <div className="main-title">
            <h1>Single Stake (3, 3)</h1>
          </div>

          <Grid container direction="column" spacing={2}>
            <Grid item>
              <div className="card-header">


                {address && oldSorkanBalance > 0.01 && (
                  <Link
                    className="migrate-sorkan-button"
                    style={{ textDecoration: "none" }}
                    href="https://docs.orkan.finance/using-the-website/migrate"
                    aria-label="migrate-sorkan"
                    target="_blank"
                  >
                    <NewReleases viewBox="0 0 24 24" />
                    <Typography>Migrate sORKAN!</Typography>
                  </Link>
                )}
              </div>
            </Grid>

            <Grid item>
              <div className="stake-top-metrics">
                <Grid container spacing={2} alignItems="flex-end">
                  <Grid item xs={12} sm={4} md={4} lg={4}>
                    <div className="stake-block stake-apy">
                      <div className="stake-icon"><span><img src={PercentageIcon} /></span></div>
                      <div className="stake-content">
                        <Typography variant="h5" color="textSecondary">
                          APY
                        </Typography>
                        <Typography variant="h4">
                          {showValue(trimmedStakingAPY, true, 3)} %
                        </Typography>
                      </div>
                    </div>
                  </Grid>

                  <Grid item xs={12} sm={4} md={4} lg={4}>
                    <div className="stake-block stake-tvl">
                      <div className="stake-icon"><span><img src={PiggyBankIcon} /></span></div>
                      <div className="stake-content">
                        <Typography variant="h5" color="textSecondary">
                          Total Value Deposited
                        </Typography>
                        <Typography variant="h4">
                          ${showValue(stakingTVL, false, 2)}
                        </Typography>
                      </div>
                    </div>
                  </Grid>

                  <Grid item xs={12} sm={4} md={4} lg={4}>
                    <div className="stake-block stake-index">
                      <div className="stake-icon"><span><img src={CodeRebaseIcon} /></span></div>
                      <div className="stake-content">
                        <Typography variant="h5" color="textSecondary">
                          Current Index
                        </Typography>
                        <Typography variant="h4">
                          {showValue(currentIndex, false, 2)}
                        </Typography>
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </div>
            </Grid>
          </Grid>
        </div>
      </Zoom>
      <Zoom in={true} onEntered={() => setZoomed(true)}>
        <Paper className={`orkan-card`}>

          <div className="staking-area">
            <RebaseTimer />
            {!address ? (
              <div className="stake-wallet-notification">
                <div className="wallet-menu" id="wallet-menu">
                  {modalButton}
                </div>
                <Typography variant="h6">Connect your wallet to stake ORKAN</Typography>
              </div>
            ) : (
              <>
                <Box className="stake-action-area">
                  <Tabs
                    key={String(zoomed)}
                    value={view}
                    textColor="primary"
                    indicatorColor="primary"
                    className="stake-tab-buttons"
                    onChange={changeView}
                    aria-label="stake tabs"
                  >
                    <Tab label="Stake" {...a11yProps(0)} />
                    <Tab label="Unstake" {...a11yProps(1)} />
                  </Tabs>

                  <Box className="stake-action-row " display="flex">
                    {address && !isAllowanceDataLoading ? (
                      (!hasAllowance("orkan") && view === 0) || (!hasAllowance("sorkan") && view === 1) ? (
                        <Box className="help-text">
                          <Typography variant="body1" className="stake-note" color="textSecondary">
                            {view === 0 ? (
                              <>
                                First time staking <b>ORKAN</b>?
                                <br />
                                Please approve Strudel Dao to use your <b>ORKAN</b> for staking.
                              </>
                            ) : (
                              <>
                                First time unstaking <b>sORKAN</b>?
                                <br />
                                Please approve Strudel Dao to use your <b>sORKAN</b> for unstaking.
                              </>
                            )}
                          </Typography>
                        </Box>
                      ) : (
                        <FormControl className="orkan-input" variant="outlined" color="primary">
                          <InputLabel htmlFor="amount-input"></InputLabel>
                          <OutlinedInput
                            id="amount-input"
                            type="number"
                            placeholder="Enter an amount"
                            className="stake-input"
                            value={quantity}
                            onChange={e => setQuantity(e.target.value)}
                            labelWidth={0}
                            endAdornment={
                              <InputAdornment position="end">
                                <Button variant="text" onClick={setMax} color="inherit">
                                  Max
                                </Button>
                              </InputAdornment>
                            }
                          />
                        </FormControl>
                      )
                    ) : (
                      <Skeleton width="150px" />
                    )}

                    <TabPanel value={view} index={0} className="stake-tab-panel">
                      {isAllowanceDataLoading ? (
                        <Skeleton />
                      ) : address && hasAllowance("orkan") ? (
                        <Button
                          className="stake-button"
                          variant="contained"
                          color="primary"
                          disabled={isPendingTxn(pendingTransactions, "staking")}
                          onClick={() => {
                            onChangeStake("stake");
                          }}
                        >
                          {txnButtonText(pendingTransactions, "staking", "Stake ORKAN")}
                        </Button>
                      ) : (
                        <Button
                          className="stake-button"
                          variant="contained"
                          color="primary"
                          disabled={isPendingTxn(pendingTransactions, "approve_staking")}
                          onClick={() => {
                            onSeekApproval("orkan");
                          }}
                        >
                          {txnButtonText(pendingTransactions, "approve_staking", "Approve")}
                        </Button>
                      )}
                    </TabPanel>
                    <TabPanel value={view} index={1} className="stake-tab-panel">
                      {isAllowanceDataLoading ? (
                        <Skeleton />
                      ) : address && hasAllowance("sorkan") ? (
                        <Button
                          className="stake-button"
                          variant="contained"
                          color="primary"
                          disabled={isPendingTxn(pendingTransactions, "unstaking")}
                          onClick={() => {
                            onChangeStake("unstake");
                          }}
                        >
                          {txnButtonText(pendingTransactions, "unstaking", "Unstake ORKAN")}
                        </Button>
                      ) : (
                        <Button
                          className="stake-button"
                          variant="contained"
                          color="primary"
                          disabled={isPendingTxn(pendingTransactions, "approve_unstaking")}
                          onClick={() => {
                            onSeekApproval("sorkan");
                          }}
                        >
                          {txnButtonText(pendingTransactions, "approve_unstaking", "Approve")}
                        </Button>
                      )}
                    </TabPanel>
                  </Box>
                </Box>

                <div className={`stake-user-data`}>
                  <div className="data-row">
                    <Typography variant="body1">Unstaked Balance</Typography>
                    <Typography variant="body1">
                      {isAppLoading ? <Skeleton width="80px" /> : <>{trim(orkanBalance, 4)} ORKAN</>}
                    </Typography>
                  </div>

                  <div className="data-row">
                    <Typography variant="body1">Staked Balance</Typography>
                    <Typography variant="body1">
                      {isAppLoading ? <Skeleton width="80px" /> : <>{trimmedBalance} sORKAN</>}
                    </Typography>
                  </div>

                  <div className="data-row data-row-sm">
                    <Typography variant="body2" color="textSecondary">
                      Single Staking
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      {isAppLoading ? <Skeleton width="80px" /> : <>{trim(sorkanBalance, 4)} sORKAN</>}
                    </Typography>
                  </div>

                  <Divider color="secondary" />

                  <div className="data-row">
                    <Typography variant="body1">Next Reward Amount</Typography>
                    <Typography variant="body1">
                      {isAppLoading ? <Skeleton width="80px" /> : <>{isNaN(nextRewardValue) ? 0 : nextRewardValue} sORKAN</>}
                    </Typography>
                  </div>

                  <div className="data-row">
                    <Typography variant="body1">Next Reward Yield</Typography>
                    <Typography variant="body1">
                      {isAppLoading ? <Skeleton width="80px" /> : <>{isNaN(stakingRebasePercentage) ? '-' : stakingRebasePercentage} %</>}
                    </Typography>
                  </div>

                  <div className="data-row">
                    <Typography variant="body1">ROI (5-Day Rate)</Typography>
                    <Typography variant="body1">
                      {isAppLoading ? <Skeleton width="80px" /> : <>{isNaN(trim(fiveDayRate * 100, 4)) ? '-' : trim(fiveDayRate * 100, 4)} %</>}
                    </Typography>
                  </div>
                </div>
              </>
            )}
          </div>
        </Paper>
      </Zoom>
    </div>
  );
}

export default Stake;
