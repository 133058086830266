import { useSelector } from "react-redux";
import { Skeleton } from "@material-ui/lab";
import { Typography, Box, SvgIcon } from "@material-ui/core";
import { trim, formatCurrency } from "../../../../helpers";
import InfoTooltip from "src/components/InfoTooltip/InfoTooltip.jsx";
import ChartIcon from "../../../../assets/icons/chart.svg";
import TagIcon from "../../../../assets/icons/tag.svg";
import ChartNetworkIcon from "../../../../assets/icons/chart-network.svg";
import HexagonIcon from "../../../../assets/icons/hexagon.svg";
import CodeRebaseIcon from "../../../../assets/icons/code-rebase.svg";

export const Metric = props => <Box className={`metric ${props.className}`}>{props.children}</Box>;

Metric.Value = props => <Typography variant="h5">{props.children || <Skeleton type="text" />}</Typography>;

Metric.Title = props => (
	<Typography variant="h6">
		{props.children}
	</Typography>
);

export const MarketCap = () => {
	const marketCap = useSelector(state => state.app.marketCap);
	return (
		<Metric className="market">
			<div className="metric-icon"><span><img src={ChartIcon} /></span></div>
			<div className="metric-content">
				<Metric.Title>Market Cap</Metric.Title>
				<Metric.Value>{marketCap >= 0 && formatCurrency(marketCap, 0)}</Metric.Value>
			</div>
		</Metric>
	);
};

export const ORKANPrice = () => {
	const marketPrice = useSelector(state => state.app.marketPrice);

	return (
		<Metric className="price">
			<div className="metric-icon"><span><img src={TagIcon} /></span></div>
			<div className="metric-content">
				<Metric.Title>ORKAN Price</Metric.Title>
				<Metric.Value>{marketPrice >= 0 && formatCurrency(marketPrice, 2)}</Metric.Value>
			</div>
		</Metric>
	);
};

export const CircSupply = () => {
	const circSupply = useSelector(state => state.app.circSupply);
	const totalSupply = useSelector(state => state.app.totalSupply);

	const isDataLoaded = circSupply && totalSupply;

	return (
		<Metric className="circ">
			<div className="metric-icon"><span><img src={ChartNetworkIcon} /></span></div>
			<div className="metric-content">
				<Metric.Title>Circulating Supply (total)</Metric.Title>
				<Metric.Value>{isDataLoaded && parseInt(circSupply) + " / " + parseInt(totalSupply)}</Metric.Value>
			</div>
		</Metric>
	);
};

export const BackingPerORKAN = () => {
	const backingPerOrkan = useSelector(state => state.app.treasuryMarketValue / state.app.circSupply);

	return (
		<Metric className="bpo">
			<div className="metric-icon"><span><img src={HexagonIcon} /></span></div>
			<div className="metric-content">
				<Metric.Title>Backing per ORKAN</Metric.Title>
				<Metric.Value>{!isNaN(backingPerOrkan) && formatCurrency(backingPerOrkan, 2)}</Metric.Value>
			</div>
		</Metric>
	);
};

export const CurrentIndex = () => {
	const currentIndex = useSelector(state => state.app.currentIndex);

	return (
		<Metric className="index">
			<div className="metric-icon"><span><img src={CodeRebaseIcon} /></span></div>
			<div className="metric-content">
				<Metric.Title>
					Current Index
					<InfoTooltip className="tooltip" message="The current index tracks the amount of sORKAN accumulated since the beginning of staking. Basically, how much sORKAN one would have if they staked and held a single ORKAN from day 1." />
				</Metric.Title>
				<Metric.Value>{currentIndex && trim(currentIndex, 2)}</Metric.Value>
			</div>
		</Metric>
	);
};

export const WSORKANPrice = () => {
	const baseTokenPrice = useSelector(state => state.app.baseTokenPrice);

	return (
		<Metric className="wsoprice">
			<div className="metric-icon"><span><img src={TagIcon} /></span></div>
			<div className="metric-content">
				<Metric.Title>
					FTM Price
				</Metric.Title>
				<Metric.Value>{baseTokenPrice && formatCurrency(baseTokenPrice, 3)}</Metric.Value>
			</div>
		</Metric>
	);
};
