export const THE_GRAPH_URL = "https://api.thegraph.com/subgraphs/name/cryptogazoh/orkanmainnetv12";
export const EPOCH_INTERVAL = 28800;
export const BLOCK_RATE_SECONDS = 1.3;
export const TOKEN_DECIMALS = 9;

interface IAddresses {
  [key: number]: { [key: string]: string };
}

export const addresses: IAddresses = {
  250: { // Phantom Mainnet
    ORKAN_ADDRESS:                           "0xfB66e49e303A186a4c57414Ceeed651a7a78161a",
    TREASURY_ADDRESS:                        "0x74AF4834b862de58F80Df191215195C4a461213e",
    STANDARD_BONDINGCALC_ADDRESS:            "0x3A6c3972171e8fF6EdE477f89cC1b0Fb569A5C74",
    NON_STANDARD_BONDINGCALC_ADDRESS:        "0x3DE4FFAf12dA37A71b767Ef41183F9f97e355E76",
    STAKING_ADDRESS:                         "0x965C151807C8cdb1b6C47020297BE305bB1d6047",
    SORKAN_ADDRESS:                          "0xC1dA744BedA9129D40d12445cF1C313fFd2Bee41",
    WSORKAN_ADDRESS:                         "0x420a0960a7F7dbA6D9eD83192489844D260Dc2c1",
    DISTRIBUTOR_ADDRESS:                     "0x07fbeec02a45AC3A9ed526E7fA00722Aa3c73867",
    STAKING_HELPER_ADDRESS:                  "0xaDaEA54241e80AC631645a54676ec1a3b5756Cf2",
    CIRCULATING_SUPPLY_ADDRESS:              "0xB4180A5014e752bDbA75000c620e7725875E6dc3",
    REDEEM_HELPER_ADDRESS:                   "0x22d54ab2FC3f4d30DF1c9679422D09f6373C984E"
  },
  4002: { // Phantom Testnet
    ORKAN_ADDRESS:                           "0x0000000000000000000000000000000000000000",
    TREASURY_ADDRESS:                        "0x0000000000000000000000000000000000000000",
    STANDARD_BONDINGCALC_ADDRESS:            "0x0000000000000000000000000000000000000000",
    STAKING_ADDRESS:                         "0x0000000000000000000000000000000000000000",
    SORKAN_ADDRESS:                          "0x0000000000000000000000000000000000000000",
    WSORKAN_ADDRESS:                         "0x0000000000000000000000000000000000000000",
    DISTRIBUTOR_ADDRESS:                     "0x0000000000000000000000000000000000000000",
    STAKING_HELPER_ADDRESS:                  "0x0000000000000000000000000000000000000000",
    CIRCULATING_SUPPLY_ADDRESS:              "0x0000000000000000000000000000000000000000",
    REDEEM_HELPER_ADDRESS:                   "0x0000000000000000000000000000000000000000"
  },
  1: { // Ethereum Mainnet
    ORKAN_ADDRESS:                           "0x0000000000000000000000000000000000000000",
    TREASURY_ADDRESS:                        "0x0000000000000000000000000000000000000000",
    STANDARD_BONDINGCALC_ADDRESS:            "0x0000000000000000000000000000000000000000",
    STAKING_ADDRESS:                         "0x0000000000000000000000000000000000000000",
    SORKAN_ADDRESS:                          "0x0000000000000000000000000000000000000000",
    WSORKAN_ADDRESS:                         "0x0000000000000000000000000000000000000000",
    DISTRIBUTOR_ADDRESS:                     "0x0000000000000000000000000000000000000000",
    STAKING_HELPER_ADDRESS:                  "0x0000000000000000000000000000000000000000",
    CIRCULATING_SUPPLY_ADDRESS:              "0x0000000000000000000000000000000000000000",
    REDEEM_HELPER_ADDRESS:                   "0x0000000000000000000000000000000000000000"
  },
};
